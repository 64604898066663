import "./IntroCard.scss";
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { ReactComponent as Icon } from "../../assets/images/logo.svg";

const IntroCard = ({ style }) => {
  const element = useRef();
  const cardElement = useRef();
  const tl = useRef();
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);

  useEffect(() => {
    tl.current = gsap.timeline({ delay: 1 });
    if (appState === "intro") {
      tl.current.set(element.current, { y: 400, autoAlpha: 1 }, 0);
      tl.current.to(
        cardElement.current,
        {
          autoAlpha: 1,
          duration: 0.5,
          ease: "power2.out",
        },
        0
      );
      tl.current.to(
        element.current,
        {
          y: 0,
          duration: 1.0,
          ease: "back.out",
        },
        0.0
      );
    }
  }, [appState]);

  const start = () => {
    // console.log("start");
    tl.current = gsap.timeline({ onComplete: setAppState("icons") });
    tl.current.to(
      cardElement.current,
      {
        y: 1000,
        duration: 0.5,
        ease: "power2.in",
      },
      0
    );
    tl.current.to(
      cardElement.current,
      {
        autoAlpha: 0,
        duration: 0.5,
      },
      0.3
    );
    tl.current.set(
      element.current,
      {
        autoAlpha: 0,
      },
      1
    );
  };

  return (
    <div onPointerDown={start} ref={element} className="introCard">
      <div ref={cardElement} className="introCardPanel">
        <Icon />
        <h1 className="title">
          With Google Cloud,
          <br />
          your data is more available,
          <br />
          flexible and intelligent.
        </h1>
      </div>
    </div>
  );
};

export default IntroCard;

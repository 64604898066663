import "./DataTypes.scss";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import DataTypesBox from "../DataTypesBox/DataTypesBox";
import DataTypesDropBox from "../DataTypesDropBox/DataTypesDropBox";
import DataTypesCanvas from "../DataTypesCanvas/DataTypesCanvas";
//import { data } from "../../data/data";

const DataTypes = ({ style }) => {
  const element = useRef();
  const tl = useRef();
  const appState = useStore((state) => state.appState);

  useEffect(() => {
    tl.current = gsap.timeline({});
    tl.current.set(
      element.current,
      {
        autoAlpha:
          appState === "dataTypes" ||
          appState === "dataProcessing" ||
          appState === "dataInsights" ||
          appState === "dataDetails"
            ? 1
            : 0,
        zIndex:
          appState === "dataTypes" ||
          appState === "dataProcessing" ||
          appState === "dataInsights" ||
          appState === "dataDetails"
            ? 100
            : 0,
      },
      0.6
    );

    if (appState === "returnToDataTypes") {
      tl.current.to(
        element.current,
        { width: "37vw", left: "41.65vw", duration: 0.5, ease: "sine.out" },
        0
      );
    }

    if (appState === "dataProcessing") {
      tl.current.to(
        element.current,
        { width: "17vw", duration: 0.5, ease: "sine.out" },
        0
      );
    }

    if (appState === "dataInsights") {
      //tl.current.set(element.current, { y: 100, autoAlpha: 0 }, 0);
      tl.current.to(
        element.current,
        {
          left: "28vw",
          autoAlpha: 1,
          duration: 1.0,
          ease: "sine.inOut",
        },
        0.0
      );
    }

    if (appState === "dataDetailsDeepLink") {
      //tl.current.set(element.current, { y: 100, autoAlpha: 0 }, 0);
      tl.current.to(
        element.current,
        {
          left: "28vw",
          autoAlpha: 1,
          duration: 1.0,
          ease: "sine.inOut",
        },
        0.0
      );
    }
  }, [appState]);

  return (
    <div ref={element} className="dataTypes">
      <DataTypesBox />
      <DataTypesDropBox />
      <DataTypesCanvas />
    </div>
  );
};

export default DataTypes;

import "./SectorDetail.scss";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { ReactComponent as Icon } from "../../assets/images/logo.svg";
import { useData } from "../../data/data";

const SectorDetail = ({ style }) => {
  const element = useRef();
  const tl = useRef();
  const imageRef = useRef();
  const titleRef = useRef();
  const textRef = useRef();
  const learnMoreButton = useRef();
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const selectedSector = useStore((state) => state.selectedSector);
  const data = useData((state) => state.data);

  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  const [image, setImage] = useState(null);

  useEffect(() => {
    tl.current = gsap.timeline({});
    if (appState === "dataTypes") {
      tl.current.set(element.current, { y: 100, autoAlpha: 0 }, 0);
      tl.current.to(
        element.current,
        {
          y: 0,
          autoAlpha: 1,
          duration: 1.0,
          ease: "sine.inOut",
        },
        0.0
      );
    }

    if (appState === "returnToDataTypes") {
      // tl.current.set(element.current, { y: 100, autoAlpha: 0 }, 0);
      tl.current.to(
        element.current,
        {
          y: 0,
          left: "26vw",
          autoAlpha: 1,
          duration: 1.0,
          ease: "sine.inOut",
        },
        0.0
      );
    }

    if (appState === "dataInsights") {
      //tl.current.set(element.current, { y: 100, autoAlpha: 0 }, 0);
      tl.current.to(
        element.current,
        {
          left: "11.5vw",
          autoAlpha: 1,
          duration: 1.0,
          ease: "sine.inOut",
        },
        0.0
      );
    }

    if (appState === "dataDetailsDeepLink") {
      //tl.current.set(element.current, { y: 100, autoAlpha: 0 }, 0);
      tl.current.to(
        element.current,
        {
          left: "11.5vw",
          autoAlpha: 1,
          duration: 1.0,
          ease: "sine.inOut",
        },
        2.0
      );
    }
  }, [appState]);

  useEffect(() => {
    if (selectedSector === null) return;
    tl.current = gsap.timeline({});
    tl.current.to(
      [
        imageRef.current,
        titleRef.current,
        textRef.current,
        learnMoreButton.current,
      ],
      {
        autoAlpha: 0,
        duration: 0.4,
      }
    );
    tl.current.call(
      () => {
        setImage(data.sectors[selectedSector]?.image || null);
        setTitle(data.sectors[selectedSector]?.name || null);
        setText(data.sectors[selectedSector]?.description || null);
      },
      null,
      0.4
    );
    tl.current.to(
      [
        imageRef.current,
        titleRef.current,
        textRef.current,
        learnMoreButton.current,
      ],
      {
        autoAlpha: 1,
        duration: 0.4,
      },
      0.4
    );
  }, [selectedSector]);

  const learnMoreClicked = (e) => {
    alert("learn more clicked");
  };

  return (
    <div ref={element} className="sectorDetail">
      <img ref={imageRef} className="image" src={image} />
      <h1 ref={titleRef} className="title">
        {title}
      </h1>
      <p ref={textRef}>{text}</p>
      {/* <button
        ref={learnMoreButton}
        className="learnMore"
        onClick={learnMoreClicked}
      >
        {data.sectorLearnMore}
      </button> */}
    </div>
  );
};

export default SectorDetail;

import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Tooltip.scss";

/**
 * Wraps content to provide a tooltip. Put the content that needs a tooltip inside of this element.
 * Tooltip appears when you hover or click on the wrapped contents.
 * Similar to MUI Tooltip: https://mui.com/material-ui/react-tooltip/
 *
 * @param {string} heading - Heading text
 * @param {string} content - Contents text
 * @param {object} style - Styles the container that wraps the children
 * @param {bool} below - If the tooltip opens below the content
 * @param {function} open - Function to execute on tooltip open
 * @param {bool} close - Function to execute on tooltip close
 */
const Tooltip = ({ heading, content, style, below, open, close, children }) => {
  const [visible, setVisible] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    function touchAnywhere(e) {
      if (e.target !== containerRef.current && !containerRef.current?.contains(e.target)) {
        setVisible(false);
      }
    }
    document.addEventListener("click", touchAnywhere);
    return () => {
      document.addEventListener("click", touchAnywhere);
    };
  }, []);

  useEffect(() => {
    if (visible) {
      if (open) {
        open();
      }
    } else if (close) {
      close();
    }
  }, [visible]);

  const showTooltip = useCallback(() => {
    setVisible(true);
  }, []);

  return (
    <div className="tooltip-container" ref={containerRef} style={style} onClick={showTooltip}>
      {children}
      <div className={"tooltip" + (visible ? " tooltip-visible" : "") + (below ? " tooltip-below" : "")}>
        <p className="tooltip-heading">{heading}</p>
        <p className="tooltip-content">{content}</p>
      </div>
    </div>
  );
};

export default Tooltip;

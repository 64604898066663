import "./DataProcessingBlocks.scss";
import React, { Fragment, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import useStore from "../../store";
import { ReactComponent as InfoIcon } from "../../assets/images/information.svg";
import { ReactComponent as ArrowDown } from "../../assets/images/arrow-down.svg";
import { ReactComponent as ArrowCurve } from "../../assets/images/arrow-curve-to-right.svg";
import Tooltip from "../Tooltip/Tooltip";

import { useData } from "../../data/data";

// const dataShapes = [shape1, shape2, shape3, shape4];

gsap.registerPlugin(MotionPathPlugin);

const DataProcessingBlocks = ({ style }) => {
  const element = useRef();
  const tl = useRef();
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const itemsRef = useRef([]);
  const progressBarRef = useRef([]);
  const progressBarPathRef = useRef([]);
  const progressStatus = useRef([]);
  const progressBarValue = useRef([]);
  const progressIconRef = useRef([]);
  const iconsRef = useRef([]);
  const iconsAnimatedRef = useRef([]);

  const iconsAnimatedRefSet1 = useRef([]);
  const iconsAnimatedRefSet2 = useRef([]);
  const iconsAnimatedRefSet3 = useRef([]);
  const iconsAnimatedRefSet4 = useRef([]);
  const iconsAnimatedRefSet5 = useRef([]);
  const iconsAnimatedRefSet6 = useRef([]);

  const arrowsDown = useRef([]);
  const arrowsCurve = useRef([]);
  const [openTooltip, setOpenTooltip] = useState();

  const selectedSector = useStore((state) => state.selectedSector);
  const selectedDataTypes = useStore((state) => state.selectedDataTypes);
  const setSelectedDataTypes = useStore((state) => state.setSelectedDataTypes);
  const data = useData((state) => state.data);

  useEffect(() => {
    if (appState === "dataProcessing") {
      // initialize the variables
      data.dataProcessing.nodes.map((n, i) => {
        progressStatus.current[i].innerHTML = "Not Started";
        progressBarPathRef.current[i].setAttribute("stroke-dasharray", "0,100");
      });

      tl.current = gsap.timeline({});

      // this might not work, wnat to set the staggered columsn back here
      tl.current.set(itemsRef.current, { clearProps: "all" }, 0);
      tl.current.set(progressBarValue.current, { clearProps: "all" }, 0);
      tl.current.set(progressBarPathRef.current, { clearProps: "all" }, 0);
      tl.current.set(progressStatus.current, { clearProps: "all" }, 0);
      tl.current.set(progressIconRef.current, { clearProps: "all" }, 0);
      tl.current.set(progressBarRef.current, { clearProps: "all" }, 0);
      tl.current.set(iconsRef.current, { clearProps: "all" }, 0);
      tl.current.set(arrowsDown.current, { clearProps: "all" }, 0);
      tl.current.set(arrowsCurve.current, { clearProps: "all" }, 0);

      tl.current.set(itemsRef.current, { x: "+300%", filter: "brightness(.6)" }, 0);
      tl.current.set(element.current, { autoAlpha: 1 }, 0);
      tl.current.to(
        itemsRef.current,
        {
          x: 0,
          duration: 0.7,
          ease: "sine.out",
          stagger: 0.1,
        },
        0.1
      );

      tl.current.to(
        progressBarPathRef.current,
        {
          "stroke-dasharray": 100,
          duration: 3,
          stagger: 3.5,
        },
        1
      );

      tl.current.to(
        progressBarValue.current,
        {
          textContent: 100,
          snap: { textContent: 1 },
          duration: 3,
          stagger: 3.5,
        },
        1
      );

      // Aniamte set of icons between box 1 and 2
      tl.current.to(iconsAnimatedRef.current[0], { opacity: 1 }, 0);
      tl.current.set(iconsAnimatedRefSet1.current, { y: 85, opacity: 0.0 }, 0);
      tl.current.fromTo(
        iconsAnimatedRefSet1.current,
        { y: 85, opacity: 1.0 },
        {
          y: 120,
          opacity: 0.3,
          duration: 0.5,
          stagger: 0.5,
          repeat: 6,
          ease: "sine.inOut",
        },
        4.5
      );
      tl.current.to(iconsAnimatedRef.current[0], { opacity: 0 }, 7.5);
      //

      // Aniamte  set of icons between box 2 and 3
      tl.current.to(iconsAnimatedRef.current[1], { opacity: 1 }, 0);
      tl.current.set(iconsAnimatedRefSet2.current, { y: 85, opacity: 0.0 }, 0);
      tl.current.fromTo(
        iconsAnimatedRefSet2.current,
        { y: 85, opacity: 1.0 },
        {
          //y: 120,

          opacity: 0.3,
          duration: 1,
          stagger: 0.5,
          repeat: 6,
          ease: "sine.inOut",
          motionPath: {
            offsetY: 85,
            offsetX: 10,
            path: "M0,0C.17,16.83,.33,33.66,.5,50.5c-.22,1.8-1.67,15.87,9.08,26.88,11.07,11.34,25.74,9.83,27.42,9.63H233",
            alignOrigin: [0.5, 0.5],
            autoRotate: true,
          },
        },
        8
      );
      tl.current.to(iconsAnimatedRef.current[1], { opacity: 0 }, 11);
      //

      // Aniamte set of icons between box 3 and 4
      tl.current.to(iconsAnimatedRef.current[2], { opacity: 1 }, 0);
      tl.current.set(iconsAnimatedRefSet3.current, { y: 85, opacity: 0.0 }, 0);
      tl.current.fromTo(
        iconsAnimatedRefSet3.current,
        { y: 90, opacity: 1.0 },
        {
          y: 120,
          opacity: 0.3,
          duration: 0.5,
          stagger: 0.5,
          repeat: 6,
          ease: "sine.inOut",
        },
        11.5
      );
      tl.current.to(iconsAnimatedRef.current[2], { opacity: 0 }, 14.5);
      //

      // Aniamte  set of icons between box 4 and 5
      tl.current.to(iconsAnimatedRef.current[3], { opacity: 1 }, 0);
      tl.current.set(iconsAnimatedRefSet4.current, { y: 85, opacity: 0.0 }, 0);
      tl.current.fromTo(
        iconsAnimatedRefSet4.current,
        { y: 85, opacity: 1.0 },
        {
          //y: 120,

          opacity: 0.3,
          duration: 1,
          stagger: 0.5,
          repeat: 6,
          ease: "sine.inOut",
          motionPath: {
            offsetY: 85,
            offsetX: 10,
            path: "M0,0C.17,16.83,.33,33.66,.5,50.5c-.22,1.8-1.67,15.87,9.08,26.88,11.07,11.34,25.74,9.83,27.42,9.63H233",
            alignOrigin: [0.5, 0.5],
            autoRotate: true,
          },
        },
        15
      );
      tl.current.to(iconsAnimatedRef.current[3], { opacity: 0 }, 18);
      //

      // Aniamte set of icons between box 5 and 6
      tl.current.to(iconsAnimatedRef.current[4], { opacity: 1 }, 0);
      tl.current.set(iconsAnimatedRefSet5.current, { y: 85, opacity: 0.0 }, 0);
      tl.current.fromTo(
        iconsAnimatedRefSet5.current,
        { y: 90, opacity: 1.0 },
        {
          y: 120,
          opacity: 0.3,
          duration: 0.5,
          stagger: 0.5,
          repeat: 6,
          ease: "sine.inOut",
        },
        18.5
      );
      tl.current.to(iconsAnimatedRef.current[4], { opacity: 0 }, 21.5);
      tl.current.call(
        () => {
          setAppState("dataInsights");
        },
        null,
        21.5
      );
      //

      // Aniamte set of icons between box 6 and end
      tl.current.to(iconsAnimatedRef.current[5], { opacity: 1 }, 0);
      tl.current.set(iconsAnimatedRefSet6.current, { y: 30, x: 340, scale: 1, opacity: 0.0 }, 0);
      tl.current.fromTo(
        iconsAnimatedRefSet6.current,
        { y: 30, x: 340, opacity: 1.0, scale: 1 },
        {
          x: 500,
          y: -50,
          opacity: 0.0,
          scale: 3,
          duration: 0.5,
          stagger: 0.5,
          repeat: 10,
          ease: "sine.inOut",
        },
        22
      );
      tl.current.to(iconsAnimatedRef.current[5], { opacity: 0 }, 28);
      //

      tl.current.set(
        progressStatus.current,
        {
          textContent: "In Progress",
          color: "#F9AB00",
          stagger: 3.5,
        },
        1
      );

      tl.current.set(
        progressStatus.current,
        {
          textContent: "Complete",
          color: "#34A853",
          stagger: 3.5,
        },
        4
      );

      tl.current.to(
        progressIconRef.current,
        {
          autoAlpha: 1,
          stagger: 3.5,
          duration: 0.5,
        },
        4.25
      );

      tl.current.to(
        progressBarValue.current,
        {
          autoAlpha: 0,
          stagger: 3.5,
          duration: 0.5,
        },
        4
      );

      tl.current.to(
        progressBarRef.current,
        {
          autoAlpha: 0,
          stagger: 3.5,
          duration: 0.5,
        },
        4
      );

      tl.current.to(
        iconsRef.current,
        {
          //autoAlpha: 0,
          scaleY: 1,
          height: "auto",
          stagger: 3.5,
          duration: 0.5,
        },
        1
      );

      tl.current.to(
        iconsRef.current,
        {
          //autoAlpha: 0,
          scaleY: 0,
          height: 0,
          stagger: 3.5,
          duration: 0.5,
        },
        4
      );

      tl.current.to(
        itemsRef.current,
        {
          filter: "brightness(1)",
          duration: 0.5,
          stagger: 3.5,
        },
        1
      );

      tl.current.to(
        arrowsDown.current,
        {
          autoAlpha: 1,
          duration: 0.5,
          stagger: 7,
        },
        4.5
      );

      tl.current.to(
        arrowsCurve.current,
        {
          autoAlpha: 1,
          duration: 0.5,
          stagger: 7,
        },
        8
      );
    }

    if (appState === "dataInsights") {
      tl.current = gsap.timeline({});
      tl.current.to(
        element.current,
        {
          left: "47vw",
          autoAlpha: 1,
          duration: 1.0,
          ease: "sine.inOut",
        },
        0.0
      );
    }

    if (appState === "dataDetails") {
      tl.current = gsap.timeline({});
      tl.current.to(
        arrowsDown.current,
        {
          autoAlpha: 0,
          duration: 0.5,
        },
        0
      );

      tl.current.to(
        arrowsCurve.current,
        {
          autoAlpha: 0,
          duration: 0.5,
        },
        0
      );

      tl.current.to(
        itemsRef.current,
        {
          "margin-left": 0,
          duration: 0.5,
        },
        0
      );
    }

    if (appState === "returnToDataTypes") {
      tl.current = gsap.timeline({});
      tl.current.to(
        itemsRef.current,
        {
          x: "+=200%",
          duration: 1,
          ease: "sine.in",
        },
        0
      );
      tl.current.to(
        element.current,
        {
          left: "60vw",
          autoAlpha: 0,
          duration: 1,
          ease: "sine.in",
        },
        0.0
      );
    }

    if (appState === "dataDetailsDeepLink") {
      tl.current = gsap.timeline({ delay: 4 });
      tl.current.to(
        element.current,
        {
          left: "47vw",
          autoAlpha: 1,
          duration: 1.0,
          ease: "sine.inOut",
        },
        0.0
      );

      tl.current.set(
        arrowsDown.current,
        {
          autoAlpha: 0,
        },
        0
      );

      tl.current.set(
        arrowsCurve.current,
        {
          autoAlpha: 0,
        },
        0
      );

      tl.current.set(
        itemsRef.current,
        {
          "margin-left": 0,
        },
        0
      );

      tl.current.set(itemsRef.current, { x: "+600%", filter: "brightness(.6)" }, 0);
      tl.current.set(element.current, { autoAlpha: 1 }, 0);
      tl.current.to(
        itemsRef.current,
        {
          x: 0,
          duration: 0.7,
          ease: "sine.out",
        },
        0
      );

      tl.current.set(
        progressStatus.current,
        {
          textContent: "Complete",
          color: "#34A853",
        },
        0
      );

      tl.current.set(
        progressIconRef.current,
        {
          autoAlpha: 1,
        },
        0
      );

      tl.current.set(
        progressBarValue.current,
        {
          autoAlpha: 0,
        },
        0
      );

      tl.current.set(
        progressBarRef.current,
        {
          autoAlpha: 0,
        },
        0
      );

      tl.current.set(
        itemsRef.current,
        {
          filter: "brightness(1)",
        },
        0
      );
    }

    return () => {
      tl.current = null;
    };
  }, [appState]);

  useEffect(() => {
    setSelectedDataTypes([]);
    // console.log("selectedDataTypes", selectedDataTypes);
  }, [selectedSector]);

  return (
    <div ref={element} className="dataProcessingBlocks">
      {data.dataProcessing.nodes.map((n, i) => {
        return (
          <div
            className={`processing-node ${"row-" + (i + 1) + " col-" + Math.ceil((i + 1) / 2)}`}
            style={{ zIndex: openTooltip === i ? 1001 : 1000 }}
            key={"process_" + i}
            ref={(el) => (itemsRef.current[i] = el)}>
            <div className="icons" ref={(el) => (iconsRef.current[i] = el)}>
              {selectedDataTypes.length &&
                selectedDataTypes.slice(0, 7).map((s, j) => {
                  {
                    // console.log("make icon for ", data.sectors[selectedSector].dataTypes, s);

                    return (
                      data.sectors[selectedSector].dataTypes[s] && (
                        <div className="icon-wrapper" key={"sicon_" + j}>
                          <img className="icon" src={data.sectors[selectedSector].dataTypes[s].icon} alt="" />
                          {i >= 2 && (
                            <svg
                              width="48"
                              height="48"
                              viewBox="0 0 48 48"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle
                                cx="24"
                                cy="24"
                                r="24"
                                fill={i === 2 || i === 3 ? data.sectors[selectedSector].color : "#669DF6"}
                              />
                            </svg>
                          )}
                          {data.sectors[selectedSector].dataTypes[s].shape === 0 && (i === 0 || i === 1) && (
                            <svg
                              width="59"
                              height="30"
                              viewBox="0 0 59 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <rect
                                y="0.0714111"
                                width="59"
                                height="29"
                                fill={i === 0 || i === 1 ? data.sectors[selectedSector].color : "#669DF6"}
                              />
                            </svg>
                          )}
                          {data.sectors[selectedSector].dataTypes[s].shape === 1 && (i === 0 || i === 1) && (
                            <svg
                              className="icon-back"
                              width="66"
                              height="30"
                              viewBox="0 0 66 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.1778 0.0715332L15 0L4.3934 4.26787L0 14.5714L4.3934 24.875L15 29.1429L15.1773 29.0715H50.8227L51 29.1429L61.6066 24.875L66 14.5714L61.6066 4.26787L51 0L50.8222 0.0715332H15.1778Z"
                                fill={i === 0 || i === 1 ? data.sectors[selectedSector].color : "#669DF6"}
                              />
                            </svg>
                          )}
                          {data.sectors[selectedSector].dataTypes[s].shape === 2 && (i === 0 || i === 1) && (
                            <svg
                              width="66"
                              height="30"
                              viewBox="0 0 66 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.0736 0.0715332L15 0L0 14.5714L15 29.1429L15.0734 29.0715H50.9266L51 29.1429L66 14.5714L51 0L50.9264 0.0715332H15.0736Z"
                                fill={i === 0 || i === 1 ? data.sectors[selectedSector].color : "#669DF6"}
                              />
                            </svg>
                          )}
                        </div>
                      )
                    );
                  }
                })}
            </div>
            <div className="icons-animated" ref={(el) => (iconsAnimatedRef.current[i] = el)}>
              {selectedDataTypes.length &&
                selectedDataTypes.slice(0, 7).map((s, j) => {
                  {
                    return (
                      data.sectors[selectedSector].dataTypes[s] && (
                        <div
                          className="icon-wrapper"
                          key={"sicona_" + j}
                          ref={(el) => {
                            if (i === 0) iconsAnimatedRefSet1.current[j] = el;
                            if (i === 1) iconsAnimatedRefSet2.current[j] = el;
                            if (i === 2) iconsAnimatedRefSet3.current[j] = el;
                            if (i === 3) iconsAnimatedRefSet4.current[j] = el;
                            if (i === 4) iconsAnimatedRefSet5.current[j] = el;
                            if (i === 5) iconsAnimatedRefSet6.current[j] = el;
                          }}>
                          <img className="icon" src={data.sectors[selectedSector].dataTypes[s].icon} alt="" />
                          {i >= 2 && (
                            <svg
                              width="48"
                              height="48"
                              viewBox="0 0 48 48"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle
                                cx="24"
                                cy="24"
                                r="24"
                                fill={i === 2 || i === 3 ? data.sectors[selectedSector].color : "#669DF6"}
                              />
                            </svg>
                          )}
                          {data.sectors[selectedSector].dataTypes[s].shape === 0 && (i === 0 || i === 1) && (
                            <svg
                              width="59"
                              height="30"
                              viewBox="0 0 59 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <rect
                                y="0.0714111"
                                width="59"
                                height="29"
                                fill={i === 0 || i === 1 ? data.sectors[selectedSector].color : "#669DF6"}
                              />
                            </svg>
                          )}
                          {data.sectors[selectedSector].dataTypes[s].shape === 1 && (i === 0 || i === 1) && (
                            <svg
                              className="icon-back"
                              width="66"
                              height="30"
                              viewBox="0 0 66 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.1778 0.0715332L15 0L4.3934 4.26787L0 14.5714L4.3934 24.875L15 29.1429L15.1773 29.0715H50.8227L51 29.1429L61.6066 24.875L66 14.5714L61.6066 4.26787L51 0L50.8222 0.0715332H15.1778Z"
                                fill={i === 0 || i === 1 ? data.sectors[selectedSector].color : "#669DF6"}
                              />
                            </svg>
                          )}
                          {data.sectors[selectedSector].dataTypes[s].shape === 2 && (i === 0 || i === 1) && (
                            <svg
                              width="66"
                              height="30"
                              viewBox="0 0 66 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M15.0736 0.0715332L15 0L0 14.5714L15 29.1429L15.0734 29.0715H50.9266L51 29.1429L66 14.5714L51 0L50.9264 0.0715332H15.0736Z"
                                fill={i === 0 || i === 1 ? data.sectors[selectedSector].color : "#669DF6"}
                              />
                            </svg>
                          )}
                        </div>
                      )
                    );
                  }
                })}
            </div>
            <div className="content">
              <div className="progress">
                <div className="progress-value">
                  <span ref={(el) => (progressBarValue.current[i] = el)} className="progress-value-number">
                    0
                  </span>
                  <span className="progress-value-symbol">%</span>
                </div>
                <svg viewBox="0 0 36 36" ref={(el) => (progressBarRef.current[i] = el)} className="progress-bar">
                  <path
                    ref={(el) => (progressBarPathRef.current[i] = el)}
                    d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                    fill="none"
                    stroke="#669DF6"
                    strokeWidth="3"
                    strokeDasharray={`0, 100`}
                  />
                </svg>
                <img ref={(el) => (progressIconRef.current[i] = el)} className="progress-icon" src={n.icon} alt="" />
              </div>
              <div className="details">
                <div className="title">
                  {n.title}
                  {data.sectors[selectedSector]?.dataProcessingCopy ? (
                    <Tooltip
                      heading={n.process}
                      content={data.sectors[selectedSector].dataProcessingCopy[n.title]}
                      style={{ width: 18, height: 18 }}
                      below={i === 0}
                      open={() => setOpenTooltip(i)}>
                      <InfoIcon />
                    </Tooltip>
                  ) : (
                    <InfoIcon />
                  )}
                </div>
                <div className="sub-titles">
                  <div className="progress-title" ref={(el) => (progressStatus.current[i] = el)}></div>
                  <div className="process-name">{n.process}</div>
                </div>
              </div>
            </div>
            {(i === 0 || i === 2 || i === 4) && (
              <div ref={(el) => (arrowsDown.current[i] = el)} className="arrow-down">
                <ArrowDown />
              </div>
            )}
            {(i === 1 || i === 3) && (
              <div ref={(el) => (arrowsCurve.current[i] = el)} className="arrow-curve">
                <ArrowCurve />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DataProcessingBlocks;

import { useEffect } from "react";
import { useData } from "./data/data";
import "./App.scss";
import Scene3D from "./components/Scene3D/Scene3D";
import IntroCard from "./components/IntroCard/IntroCard";
import IntroGoogleLogo from "./components/IntroGoogleLogo/IntroGoogleLogo";
import Pillars from "./components/Pillars/Pillars";
import SectorsMenu from "./components/SectorsMenu/SectorsMenu";
import SectorDetail from "./components/SectorDetail/SectorDetail";
import DataTypes from "./components/DataTypes/DataTypes";
import DataProcessingBlocks from "./components/DataProcessingBlocks/DataProcessingBlocks";
import DataInsights from "./components/DataInsights/DataInsights";
import CaseStudies from "./components/CaseStudies/CaseStudies";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import useStore from "./store";
import { data } from "./data/data";

const App = () => {
  const fetchData = useData((state) => state.fetch);
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Router>
      <Scene3D />
      <IntroGoogleLogo />
      <IntroCard />
      <Pillars />
      <SectorsMenu />
      <SectorDetail />
      <DataTypes />
      <DataProcessingBlocks />
      <DataInsights />
      <CaseStudies />
      <Routes>
        <Route path="/:sector" element={<HandleSectorRoutes />} />
        <Route path="/" element={<HandleDefaultRoute />} />
      </Routes>
    </Router>
  );
};

const HandleSectorRoutes = () => {
  const { sector } = useParams();
  const setAppState = useStore((state) => state.setAppState);
  const setSelectedSector = useStore((state) => state.setSelectedSector);
  const selectedSector = useStore((state) => state.selectedSector);

  useEffect(() => {
    if (sector) {
      data.sectors.forEach((s, i) => {
        console.log(sector, s.id);
        if (s.name.toLowerCase() === sector) {
          setSelectedSector(i);
        }
      });
      setAppState("dataDetailsDeepLink");
    }
  }, []);

  return null;
};

const HandleDefaultRoute = () => {
  const setAppState = useStore((state) => state.setAppState);
  const appState = useStore((state) => state.appState);

  useEffect(() => {
    if (appState === null) {
      setAppState("intro");
    }
  }, []);

  return null;
};

export default App;

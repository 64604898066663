import "./CaseStudies.scss";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import VideoPlayIcon from "../../assets/images/play-icon.svg";
import ArrowBackIcon from "../../assets/images/arrow-back.svg";
import ArrowNextIcon from "../../assets/images/arrow-next.svg";

// import ReactPlayer from "react-player";
import { useData } from "../../data/data";

const CaseStudies = ({ style }) => {
  const data = useData((state) => state.data);
  const element = useRef();
  const cardElement = useRef();
  const tl = useRef();
  const closeButtonRef = useRef();
  const mediaRef = useRef();
  const mediaInfoRef = useRef();
  const backButton = useRef();
  const nextButton = useRef();
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const selectedSector = useStore((state) => state.selectedSector);
  const caseStudiesOpen = useStore((state) => state.caseStudiesOpen);
  const setCaseStudiesOpen = useStore((state) => state.setCaseStudiesOpen);

  const [url, setUrl] = useState(null);
  const [title, setTitle] = useState(null);
  const [subTitle, setSubTitle] = useState(null);
  const [text, setText] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  const [index, setIndex] = useState(0);

  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoPlayIconRef = useRef();

  useEffect(() => {
    tl.current = gsap.timeline({});
    if (caseStudiesOpen) {
      tl.current.set(element.current, { display: "flex" }, 0);
      tl.current.to(element.current, { autoAlpha: 1, duration: 0.5 }, 0);
      tl.current.to(cardElement.current, { autoAlpha: 1, duration: 0.5 }, 0);
    } else {
      tl.current.to(cardElement.current, { autoAlpha: 0, duration: 0.5 }, 0);
      tl.current.to(element.current, { autoAlpha: 0, duration: 0.5 }, 0);
      tl.current.set(element.current, { display: "none", autoAlpha: 0 });
    }
  }, [caseStudiesOpen]);

  useEffect(() => {
    console.log("data update", data);
    if (selectedSector === null) return;
    setSubTitle(data.sectors[selectedSector].caseStudies[0].subTitle);
    setTitle(data.sectors[selectedSector].caseStudies[0].title);
    setText(data.sectors[selectedSector].caseStudies[0].text);
    setQrCode(data.sectors[selectedSector].caseStudies[0].qrCode);
    setUrl(data.sectors[selectedSector].caseStudies[0].videoURL);
  }, [data]);

  useEffect(() => {
    setIndex(0);
    if (selectedSector === null) return;
    setSubTitle(data.sectors[selectedSector].caseStudies[0].subTitle);
    setTitle(data.sectors[selectedSector].caseStudies[0].title);
    setText(data.sectors[selectedSector].caseStudies[0].text);
    setQrCode(data.sectors[selectedSector].caseStudies[0].qrCode);
    setUrl(data.sectors[selectedSector].caseStudies[0].videoURL);
  }, [selectedSector]);

  useEffect(() => {
    tl.current = gsap.timeline({});
    tl.current.to(
      [mediaRef.current, mediaInfoRef.current],
      { autoAlpha: 0, duration: 0.5 },
      0
    );
    tl.current.call(
      () => {
        if (selectedSector !== null) {
          setSubTitle(data.sectors[selectedSector].caseStudies[0].subTitle);
          setTitle(data.sectors[selectedSector].caseStudies[0].title);
          setText(data.sectors[selectedSector].caseStudies[0].text);
          setQrCode(data.sectors[selectedSector].caseStudies[0].qrCode);
          setUrl(data.sectors[selectedSector].caseStudies[0].videoURL);
        }
      },
      null,
      0.5
    );
    // fade tehm back in
    tl.current.to(
      [mediaRef.current, mediaInfoRef.current],
      { autoAlpha: 1, duration: 0.5 },
      1
    );
    if (videoPlaying) setVideoPlaying(false);
  }, [index]);

  const close = (e) => {
    e.stopPropagation();
    setVideoPlaying(false);
    setCaseStudiesOpen(false);
  };

  const playButtonClicked = (e) => {
    e.stopPropagation();
    setVideoPlaying(!videoPlaying);
  };

  const videoClicked = (e) => {
    e.stopPropagation();
    console.log("got it");
    setVideoPlaying(!videoPlaying);
  };

  const backtButtonClicked = (e) => {
    e.stopPropagation();
    setIndex(
      index === 0
        ? data.sectors[selectedSector].caseStudies.length - 1
        : index - 1
    );
  };

  const nextButtonClicked = (e) => {
    e.stopPropagation();
    setIndex(
      index === data.sectors[selectedSector].caseStudies.length - 1
        ? 0
        : index + 1
    );
  };

  useEffect(() => {
    gsap.to(videoPlayIconRef.current, {
      autoAlpha: videoPlaying ? 0.01 : 1,
      duration: 0.5,
    });
  }, [videoPlaying]);

  return (
    <div ref={element} className="caseStudies">
      <div ref={cardElement} className="panel">
        <div
          ref={mediaRef}
          className="content-media"
          // onClick={videoClicked}
        >
          <iframe
            src={url ? url + "/embed?start=true&loop=true&delayms=3000" : ""}
            width={1525}
            height={784}
            allowFullScreen={true}
          ></iframe>
          {/* <ReactPlayer
            url={url}
            playing={videoPlaying}
            loop={true}
            controls={false}
            width="100%"
            height="100%"
            config={{
              youtube: {
                playerVars: { showinfo: 1, controls: 0, modestbranding: 1 },
              },
            }}
          />
          <button
            ref={videoPlayIconRef}
            onClick={playButtonClicked}
            className="play-button"
          >
            <img src={VideoPlayIcon} alt="Play" />
          </button> */}
          {/* <div className="nav">
            <button
              className="arrow-back"
              alt="back"
              ref={backButton}
              onClick={backtButtonClicked}
            >
              <img src={ArrowBackIcon} alt="" />
            </button>

            <span className="index-value">{index + 1}</span>
            <button
              alt="next"
              ref={nextButton}
              onClick={nextButtonClicked}
              className="arrow-next"
            >
              <img src={ArrowNextIcon} alt="" />
            </button>
          </div> */}
        </div>
        <div ref={mediaInfoRef} className="content-media-info">
          <div className="qr-code">
            <img alt="" src={qrCode} />
          </div>
          <div className="info">
            {/* <h4>{subTitle}</h4> */}
            <h1>{title}</h1>
            {/* <p>{text}</p> */}
          </div>
        </div>
        <button onClick={close} ref={closeButtonRef} className="close">
          Close
        </button>
      </div>
    </div>
  );
};

export default CaseStudies;

import "./DataTypesBox.scss";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { useData } from "../../data/data";
import TopImage from "../../assets/images/data-types-top.png";

const DataTypesBox = ({ style }) => {
  const element = useRef();
  const tl = useRef();
  const appState = useStore((state) => state.appState);
  const selectedSector = useStore((state) => state.selectedSector);
  const data = useData((state) => state.data);
  useEffect(() => {
    if (appState === "intro") {
      tl.current = gsap.timeline({});
      tl.current.set(element.current, { y: 100, autoAlpha: 0 }, 0);
    }
    if (appState === "dataTypes") {
      tl.current = gsap.timeline({ delay: 0.3 });
      tl.current.to(
        element.current,
        {
          y: 0,
          autoAlpha: 1,
          duration: 1.0,
          ease: "sine.inOut",
        },
        0.0
      );
    }
  }, [appState]);

  useEffect(() => {
    //console.log(selectedSector);
    if (selectedSector === null) return;
    tl.current = gsap.timeline({});

    tl.current.to(
      element.current,
      {
        background: data.sectors[selectedSector].color,
        duration: 1,
      },
      0
    );
  }, [selectedSector]);

  return (
    <div ref={element} className="dataTypesBox">
      <h1>{data.dataTypes.dataTypesTitle}</h1>
      <div className="top-image">
        <img src={TopImage} alt="" />
      </div>
      <h2>{data.dataTypes.dataTypesText}</h2>
      <div className="bottom-key">
        {data.dataTypes.dataTypeKey.map((k, i) => {
          return (
            <div className="key" key={"key_" + i}>
              <img src={k.image} alt="" />
              <span>{k.title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DataTypesBox;

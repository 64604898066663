import create from "zustand";
import { collection, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const data = {
  dataTypes: {
    dataTypesTitle: "Data Types",
    dataTypesText: "Fling “data types” to the right to generate insights.",
    dataTypeKey: [
      {
        title: "images",
        image: "./assets/images/data-type-shapes/images-shape.svg",
      },
      {
        title: "sensors",
        image: "./assets/images/data-type-shapes/sensors-shape.svg",
      },
      {
        title: "feeds",
        image: "./assets/images/data-type-shapes/feeds-shape.svg",
      },
    ],
    rawData: "Raw Data",
  },
  sectorLearnMore: "Learn More",
  sectors: [
    {
      name: "Agriculture",
      description:
        "With extreme weather and poplulation growth putting more pressure on harvests, farmers still regularly plant by hand and tradition -- with data collection and analysis requiring lots of manual effort.",
      image: "./assets/images/sectors/agriculture.png",
      id: "agriculture",
      color: "#34A853",
      accentColor: "#A8DAB5",
      menuIcon: { file: "./assets/images/icons/agriculture/agriculture-2.png" },
      spirals: [
        { file: "./assets/images/icons/agriculture/agriculture-spiral-1.png" },
        { file: "./assets/images/icons/agriculture/agriculture-spiral-2.png" },
      ],
      icons: [
        { file: "./assets/images/icons/agriculture/agriculture-1.png" },
        { file: "./assets/images/icons/agriculture/agriculture-2.png" },
        { file: "./assets/images/icons/agriculture/agriculture-3.png" },
        { file: "./assets/images/icons/agriculture/agriculture-4.png" },
        { file: "./assets/images/icons/agriculture/agriculture-5.png" },
        { file: "./assets/images/icons/agriculture/agriculture-6.png" },
        { file: "./assets/images/icons/agriculture/agriculture-7.png" },
        { file: "./assets/images/icons/agriculture/agriculture-8.png" },
        { file: "./assets/images/icons/agriculture/agriculture-9.png" },
        { file: "./assets/images/icons/agriculture/agriculture-10.png" },
        { file: "./assets/images/icons/agriculture/agriculture-11.png" },
        { file: "./assets/images/icons/agriculture/agriculture-12.png" },
      ],
      dataTypes: [
        {
          name: "crops",
          icon: "./assets/images/icons/agriculture/agriculture-1.png",
          shape: 0,
          type: "image",
        },
        {
          name: "leaves",
          icon: "./assets/images/icons/agriculture/agriculture-2.png",
          shape: 0,
          type: "image",
        },
        {
          name: "soil",
          icon: "./assets/images/icons/agriculture/agriculture-3.png",
          shape: 0,
          type: "image",
        },

        {
          name: "soil moisture",
          icon: "./assets/images/icons/agriculture/agriculture-3.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "soil composition",
          icon: "./assets/images/icons/agriculture/agriculture-3.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "Temperature",
          icon: "./assets/images/icons/agriculture/agriculture-3.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "Wind",
          icon: "./assets/images/icons/agriculture/agriculture-3.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "air particulates",
          icon: "./assets/images/icons/agriculture/agriculture-3.png",
          shape: 1,
          type: "sensors",
        },

        {
          name: "Weather",
          icon: "./assets/images/icons/agriculture/agriculture-3.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "planting schedule",
          icon: "./assets/images/icons/agriculture/agriculture-3.png",
          shape: 2,
          type: "feeds",
        },
      ],
      dataInsights: [
        {
          title: "Crop Health",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Optimized Planting Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Crop Rotation Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Disease Detection",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Fertilization Planning",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
      ],
      caseStudies: [
        {
          videoURL: "",
          subTitle: "Crop Health",
          title: "John Deere - Case Study",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
          qrCode: "./assets/images/case-studies/case-study-1-qr-code.png",
        },
      ],
      dataInsightsCompleteImage: {
        file: "./assets/images/data-insights/data-insights-top-complete.png",
      },
    },
    {
      name: "Automotive",
      description:
        "While advances in self-driving, safety, and fuel / energy efficiency have emerged, much of this technology is still limited, and requires human intervention alongside more advanced algorithms and infrastructure.",
      image: "./assets/images/sectors/automotive.png",
      id: "automotive",
      color: "#188038",
      accentColor: "#5BB974",
      menuIcon: { file: "./assets/images/icons/automotive/automotive-12.png" },
      spirals: [
        { file: "./assets/images/icons/automotive/automotive-spiral-1.png" },
        { file: "./assets/images/icons/automotive/automotive-spiral-2.png" },
      ],
      icons: [
        { file: "./assets/images/icons/automotive/automotive-1.png" },
        { file: "./assets/images/icons/automotive/automotive-2.png" },
        { file: "./assets/images/icons/automotive/automotive-3.png" },
        { file: "./assets/images/icons/automotive/automotive-4.png" },
        { file: "./assets/images/icons/automotive/automotive-5.png" },
        { file: "./assets/images/icons/automotive/automotive-6.png" },
        { file: "./assets/images/icons/automotive/automotive-7.png" },
        { file: "./assets/images/icons/automotive/automotive-8.png" },
        { file: "./assets/images/icons/automotive/automotive-9.png" },
        { file: "./assets/images/icons/automotive/automotive-10.png" },
        { file: "./assets/images/icons/automotive/automotive-11.png" },
        { file: "./assets/images/icons/automotive/automotive-12.png" },
      ],
      dataTypes: [
        {
          name: "vehicle",
          icon: "./assets/images/icons/automotive/automotive-12.png",
          shape: 0,
          type: "image",
        },
        {
          name: "driver",
          icon: "./assets/images/icons/automotive/automotive-12.png",
          shape: 0,
          type: "image",
        },
        {
          name: "road conditions",
          icon: "./assets/images/icons/automotive/automotive-12.png",
          shape: 0,
          type: "image",
        },
        {
          name: "hazards",
          icon: "./assets/images/icons/automotive/automotive-12.png",
          shape: 0,
          type: "image",
        },
        {
          name: "traffic",
          icon: "./assets/images/icons/automotive/automotive-12.png",
          shape: 0,
          type: "image",
        },

        {
          name: "speed",
          icon: "./assets/images/icons/automotive/automotive-12.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "tires",
          icon: "./assets/images/icons/automotive/automotive-12.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "proximity",
          icon: "./assets/images/icons/automotive/automotive-8.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "fuel",
          icon: "./assets/images/icons/automotive/automotive-9.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "battery",
          icon: "./assets/images/icons/automotive/automotive-10.png",
          shape: 1,
          type: "sensors",
        },

        {
          name: "driver awareness",
          icon: "./assets/images/icons/automotive/automotive-11.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "battery health",
          icon: "./assets/images/icons/automotive/automotive-11.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "tire condition",
          icon: "./assets/images/icons/automotive/automotive-11.png",
          shape: 2,
          type: "feeds",
        },
      ],
      dataInsights: [
        {
          title: "Crop Health",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Optimized Planting Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Crop Rotation Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Disease Detection",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Fertilization Planning",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
      ],
      caseStudies: [
        {
          videoURL: "",
          subTitle: "Crop Health",
          title: "John Deere - Case Study",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
          qrCode: "./assets/images/case-studies/case-study-1-qr-code.png",
        },
      ],
      dataInsightsCompleteImage: {
        file: "./assets/images/data-insights/data-insights-top-complete.png",
      },
    },
    {
      name: "Energy",
      description:
        "The need for reliable, cost-efficient, and clean energy has created pressure on producers to optimize resource extraction, support aging electrical grids, and build carbon-neural systems that can sustain extreme weather and create safer jobs.",
      image: "./assets/images/sectors/energy.png",
      id: "energy",
      color: "#FCC934",
      accentColor: "#FDE293",
      menuIcon: { file: "./assets/images/icons/energy/energy-5.png" },
      spirals: [
        {
          file: "./assets/images/icons/energy/energy-spiral-1.png",
        },
        {
          file: "./assets/images/icons/energy/energy-spiral-2.png",
        },
      ],
      icons: [
        { file: "./assets/images/icons/energy/energy-1.png" },
        { file: "./assets/images/icons/energy/energy-2.png" },
        { file: "./assets/images/icons/energy/energy-3.png" },
        { file: "./assets/images/icons/energy/energy-4.png" },
        { file: "./assets/images/icons/energy/energy-5.png" },
        { file: "./assets/images/icons/energy/energy-6.png" },
        { file: "./assets/images/icons/energy/energy-7.png" },
        { file: "./assets/images/icons/energy/energy-8.png" },
        { file: "./assets/images/icons/energy/energy-9.png" },
        { file: "./assets/images/icons/energy/energy-10.png" },
        { file: "./assets/images/icons/energy/energy-11.png" },
        { file: "./assets/images/icons/energy/energy-12.png" },
      ],
      dataTypes: [
        {
          name: "equipment",
          icon: "./assets/images/icons/energy/energy-1.png",
          shape: 0,
          type: "image",
        },
        {
          name: "workers",
          icon: "./assets/images/icons/energy/energy-2.png",
          shape: 0,
          type: "image",
        },
        {
          name: "oil rigs",
          icon: "./assets/images/icons/energy/energy-3.png",
          shape: 0,
          type: "image",
        },
        {
          name: "wind farms",
          icon: "./assets/images/icons/energy/energy-4.png",
          shape: 0,
          type: "image",
        },
        {
          name: "geothermal plants",
          icon: "./assets/images/icons/energy/energy-5.png",
          shape: 0,
          type: "image",
        },

        {
          name: "heart rate",
          icon: "./assets/images/icons/energy/energy-6.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "temperature",
          icon: "./assets/images/icons/energy/energy-7.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "vibration",
          icon: "./assets/images/icons/energy/energy-8.png",
          shape: 1,
          type: "sensors",
        },

        {
          name: "weather",
          icon: "./assets/images/icons/energy/energy-9.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "productivity",
          icon: "./assets/images/icons/energy/energy-10.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "pprocurement",
          icon: "./assets/images/icons/energy/energy-11.png",
          shape: 2,
          type: "feeds",
        },
      ],
      dataInsights: [
        {
          title: "Crop Health",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Optimized Planting Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Crop Rotation Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Disease Detection",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Fertilization Planning",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
      ],
      caseStudies: [
        {
          videoURL: "",
          subTitle: "Crop Health",
          title: "John Deere - Case Study",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
          qrCode: "./assets/images/case-studies/case-study-1-qr-code.png",
        },
      ],
      dataInsightsCompleteImage: {
        file: "./assets/images/data-insights/data-insights-top-complete.png",
      },
    },
    {
      name: "Healthcare",
      description:
        "People are getting sicker, our population is aging, and the healthcare system is widely understaffed and mostly operating on legacy systems.",
      image: "./assets/images/sectors/healthcare.png",
      id: "healthcare",
      color: "#4285F4",
      accentColor: "#AECBFA",
      menuIcon: { file: "./assets/images/icons/healthcare/healthcare-3.png" },
      spirals: [
        { file: "./assets/images/icons/healthcare/healthcare-spiral-1.png" },
        { file: "./assets/images/icons/healthcare/healthcare-spiral-2.png" },
      ],
      icons: [
        { file: "./assets/images/icons/healthcare/healthcare-1.png" },
        { file: "./assets/images/icons/healthcare/healthcare-2.png" },
        { file: "./assets/images/icons/healthcare/healthcare-3.png" },
        { file: "./assets/images/icons/healthcare/healthcare-4.png" },
        { file: "./assets/images/icons/healthcare/healthcare-5.png" },
        { file: "./assets/images/icons/healthcare/healthcare-6.png" },
        { file: "./assets/images/icons/healthcare/healthcare-7.png" },
        { file: "./assets/images/icons/healthcare/healthcare-8.png" },
        { file: "./assets/images/icons/healthcare/healthcare-9.png" },
        { file: "./assets/images/icons/healthcare/healthcare-10.png" },
        { file: "./assets/images/icons/healthcare/healthcare-11.png" },
        { file: "./assets/images/icons/healthcare/healthcare-12.png" },
        { file: "./assets/images/icons/healthcare/healthcare-13.png" },
        { file: "./assets/images/icons/healthcare/healthcare-14.png" },
      ],
      dataTypes: [
        {
          name: "scans",
          icon: "./assets/images/icons/healthcare/healthcare-1.png",
          shape: 0,
          type: "image",
        },
        {
          name: "MRIs",
          icon: "./assets/images/icons/healthcare/healthcare-2.png",
          shape: 0,
          type: "image",
        },
        {
          name: "xrays",
          icon: "./assets/images/icons/healthcare/healthcare-2.png",
          shape: 0,
          type: "image",
        },
        {
          name: "photographs",
          icon: "./assets/images/icons/healthcare/healthcare-3.png",
          shape: 0,
          type: "image",
        },
        {
          name: "imaging",
          icon: "./assets/images/icons/healthcare/healthcare-3.png",
          shape: 0,
          type: "image",
        },

        {
          name: "temperature",
          icon: "./assets/images/icons/healthcare/healthcare-5.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "sleep",
          icon: "./assets/images/icons/healthcare/healthcare-7.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "blood pressure",
          icon: "./assets/images/icons/healthcare/healthcare-8.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "heart monitor",
          icon: "./assets/images/icons/healthcare/healthcare-9.png",
          shape: 1,
          type: "sensors",
        },

        {
          name: "EHR",
          icon: "./assets/images/icons/healthcare/healthcare-10.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "doctor notes",
          icon: "./assets/images/icons/healthcare/healthcare-11.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "prescriptions",
          icon: "./assets/images/icons/healthcare/healthcare-12.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "treatments",
          icon: "./assets/images/icons/healthcare/healthcare-14.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "Hospital visits",
          icon: "./assets/images/icons/healthcare/healthcare-14.png",
          shape: 2,
          type: "feeds",
        },
      ],
      dataInsights: [
        {
          title: "Crop Health",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Optimized Planting Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Crop Rotation Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Disease Detection",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Fertilization Planning",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
      ],
      caseStudies: [
        {
          videoURL: "",
          subTitle: "Crop Health",
          title: "John Deere - Case Study",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
          qrCode: "./assets/images/case-studies/case-study-1-qr-code.png",
        },
      ],
      dataInsightsCompleteImage: {
        file: "./assets/images/data-insights/data-insights-top-complete.png",
      },
    },
    {
      name: "Insurance",
      description:
        "Actuarial tables and underwriting models are undergoing unprecedented challenges as extreme weather, pandemics, population migration, digital identities, and changing behaviors fundamentally alter how we live our lives.",
      image: "./assets/images/sectors/insurance.png",
      id: "insurance",
      color: "#1967D2",
      accentColor: "#8AB4F8",
      menuIcon: { file: "./assets/images/icons/insurance/insurance-10.png" },
      spirals: [
        {
          file: "./assets/images/icons/insurance/insurance-spiral-1.png",
        },
        {
          file: "./assets/images/icons/insurance/insurance-spiral-2.png",
        },
      ],
      icons: [
        { file: "./assets/images/icons/insurance/insurance-1.png" },
        { file: "./assets/images/icons/insurance/insurance-2.png" },
        { file: "./assets/images/icons/insurance/insurance-3.png" },
        { file: "./assets/images/icons/insurance/insurance-4.png" },
        { file: "./assets/images/icons/insurance/insurance-5.png" },
        { file: "./assets/images/icons/insurance/insurance-6.png" },
        { file: "./assets/images/icons/insurance/insurance-7.png" },
        { file: "./assets/images/icons/insurance/insurance-8.png" },
        { file: "./assets/images/icons/insurance/insurance-9.png" },
        { file: "./assets/images/icons/insurance/insurance-10.png" },
        { file: "./assets/images/icons/insurance/insurance-11.png" },
        { file: "./assets/images/icons/insurance/insurance-12.png" },
      ],
      dataTypes: [
        {
          name: "vehicles",
          icon: "./assets/images/icons/insurance/insurance-5.png",
          shape: 0,
          type: "image",
        },
        {
          name: "victims/consumers",
          icon: "./assets/images/icons/insurance/insurance-3.png",
          shape: 0,
          type: "image",
        },
        {
          name: "buildings",
          icon: "./assets/images/icons/insurance/insurance-4.png",
          shape: 0,
          type: "image",
        },
        {
          name: "environment",
          icon: "./assets/images/icons/insurance/insurance-2.png",
          shape: 0,
          type: "image",
        },
        {
          name: "accidents",
          icon: "./assets/images/icons/insurance/insurance-6.png",
          shape: 0,
          type: "image",
        },

        {
          name: "speed",
          icon: "./assets/images/icons/insurance/insurance-11.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "temperature",
          icon: "./assets/images/icons/insurance/insurance-12.png",
          shape: 1,
          type: "sensors",
        },

        {
          name: "purchase records",
          icon: "./assets/images/icons/insurance/insurance-10.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "health records",
          icon: "./assets/images/icons/insurance/insurance-9.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "weather",
          icon: "./assets/images/icons/insurance/insurance-8.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "seasonal",
          icon: "./assets/images/icons/insurance/insurance-7.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "demographics",
          icon: "./assets/images/icons/insurance/insurance-6.png",
          shape: 2,
          type: "feeds",
        },
      ],
      dataInsights: [
        {
          title: "Crop Health",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Optimized Planting Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Crop Rotation Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Disease Detection",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Fertilization Planning",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
      ],
      caseStudies: [
        {
          videoURL: "",
          subTitle: "Crop Health",
          title: "John Deere - Case Study",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
          qrCode: "./assets/images/case-studies/case-study-1-qr-code.png",
        },
      ],
      dataInsightsCompleteImage: {
        file: "./assets/images/data-insights/data-insights-top-complete.png",
      },
    },
    {
      name: "Public Sector",
      description:
        "Congestion and safety are at the top of urban planners' minds as they evaluate new solutions to address the growing traffic and road accident problems in expanding cities.",
      image: "./assets/images/sectors/public-sector.png",
      id: "public-sector",
      color: "#C5221F",
      accentColor: "#F28B82",
      menuIcon: {
        file: "./assets/images/icons/public-sector/public-sector-14.png",
      },
      spirals: [
        {
          file: "./assets/images/icons/public-sector/public-sector-spiral-1.png",
        },
        {
          file: "./assets/images/icons/public-sector/public-sector-spiral-2.png",
        },
      ],
      icons: [
        { file: "./assets/images/icons/public-sector/public-sector-1.png" },
        { file: "./assets/images/icons/public-sector/public-sector-2.png" },
        { file: "./assets/images/icons/public-sector/public-sector-3.png" },
        { file: "./assets/images/icons/public-sector/public-sector-4.png" },
        { file: "./assets/images/icons/public-sector/public-sector-5.png" },
        { file: "./assets/images/icons/public-sector/public-sector-6.png" },
        { file: "./assets/images/icons/public-sector/public-sector-7.png" },
        { file: "./assets/images/icons/public-sector/public-sector-8.png" },
        { file: "./assets/images/icons/public-sector/public-sector-9.png" },
        { file: "./assets/images/icons/public-sector/public-sector-10.png" },
        { file: "./assets/images/icons/public-sector/public-sector-11.png" },
        { file: "./assets/images/icons/public-sector/public-sector-12.png" },
        { file: "./assets/images/icons/public-sector/public-sector-13.png" },
        { file: "./assets/images/icons/public-sector/public-sector-14.png" },
        { file: "./assets/images/icons/public-sector/public-sector-15.png" },
        { file: "./assets/images/icons/public-sector/public-sector-16.png" },
      ],
      dataTypes: [
        {
          name: "vehicles",
          icon: "./assets/images/icons/public-sector/public-sector-13.png",
          shape: 0,
          type: "image",
        },
        {
          name: "pedestrians",
          icon: "./assets/images/icons/public-sector/public-sector-1.png",
          shape: 0,
          type: "image",
        },
        {
          name: "roads",
          icon: "./assets/images/icons/public-sector/public-sector-2.png",
          shape: 0,
          type: "image",
        },
        {
          name: "traffic cams",
          icon: "./assets/images/icons/public-sector/public-sector-11.png",
          shape: 0,
          type: "image",
        },
        {
          name: "bicycles",
          icon: "./assets/images/icons/public-sector/public-sector-5.png",
          shape: 0,
          type: "image",
        },

        {
          name: "speed",
          icon: "./assets/images/icons/public-sector/public-sector-7.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "temperature",
          icon: "./assets/images/icons/public-sector/public-sector-6.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "moisture",
          icon: "./assets/images/icons/public-sector/public-sector-5.png",
          shape: 1,
          type: "sensors",
        },

        {
          name: "tickets",
          icon: "./assets/images/icons/public-sector/public-sector-14.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "police reports",
          icon: "./assets/images/icons/public-sector/public-sector-13.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "construction",
          icon: "./assets/images/icons/public-sector/public-sector-12.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "road work",
          icon: "./assets/images/icons/public-sector/public-sector-11.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "hazards",
          icon: "./assets/images/icons/public-sector/public-sector-7.png",
          shape: 2,
          type: "feeds",
        },
      ],
      dataInsights: [
        {
          title: "Crop Health",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Optimized Planting Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Crop Rotation Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Disease Detection",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Fertilization Planning",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
      ],
      caseStudies: [
        {
          videoURL: "",
          subTitle: "Crop Health",
          title: "John Deere - Case Study",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
          qrCode: "./assets/images/case-studies/case-study-1-qr-code.png",
        },
      ],
      dataInsightsCompleteImage: {
        file: "./assets/images/data-insights/data-insights-top-complete.png",
      },
    },
    {
      name: "Retail",
      description:
        "Shoppers are demanding that their loyalty and personalization data be applied at every physical and digital touchpoint, and expect to be presented with the right products at the right time.",
      image: "./assets/images/sectors/retail.png",
      id: "retail",
      color: "#EA4335",
      accentColor: "#F6AEA9",
      menuIcon: { file: "./assets/images/icons/retail/retail-14.png" },
      spirals: [
        { file: "./assets/images/icons/retail/retail-spiral-1.png" },
        { file: "./assets/images/icons/retail/retail-spiral-2.png" },
      ],
      icons: [
        { file: "./assets/images/icons/retail/retail-1.png" },
        { file: "./assets/images/icons/retail/retail-2.png" },
        { file: "./assets/images/icons/retail/retail-3.png" },
        { file: "./assets/images/icons/retail/retail-4.png" },
        { file: "./assets/images/icons/retail/retail-5.png" },
        { file: "./assets/images/icons/retail/retail-6.png" },
        { file: "./assets/images/icons/retail/retail-7.png" },
        { file: "./assets/images/icons/retail/retail-8.png" },
        { file: "./assets/images/icons/retail/retail-9.png" },
        { file: "./assets/images/icons/retail/retail-10.png" },
        { file: "./assets/images/icons/retail/retail-11.png" },
        { file: "./assets/images/icons/retail/retail-12.png" },
        { file: "./assets/images/icons/retail/retail-13.png" },
        { file: "./assets/images/icons/retail/retail-14.png" },
      ],
      dataTypes: [
        {
          name: "products",
          icon: "./assets/images/icons/retail/retail-1.png",
          shape: 0,
          type: "image",
        },
        {
          name: "shoppers",
          icon: "./assets/images/icons/retail/retail-2.png",
          shape: 0,
          type: "image",
        },

        {
          name: "RFID",
          icon: "./assets/images/icons/retail/retail-3.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "NFC",
          icon: "./assets/images/icons/retail/retail-4.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "biometrics",
          icon: "./assets/images/icons/retail/retail-5.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "bluetooth",
          icon: "./assets/images/icons/retail/retail-5.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "bluetooth",
          icon: "./assets/images/icons/retail/retail-6.png",
          shape: 1,
          type: "sensors",
        },

        {
          name: "seasonal",
          icon: "./assets/images/icons/retail/retail-7.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "sales",
          icon: "./assets/images/icons/retail/retail-7.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "customer history",
          icon: "./assets/images/icons/retail/retail-7.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "inventory",
          icon: "./assets/images/icons/retail/retail-8.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "pricing",
          icon: "./assets/images/icons/retail/retail-3.png",
          shape: 2,
          type: "feeds",
        },
      ],
      dataInsights: [
        {
          title: "Crop Health",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Optimized Planting Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Crop Rotation Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Disease Detection",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Fertilization Planning",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
      ],
      caseStudies: [
        {
          videoURL: "",
          subTitle: "Crop Health",
          title: "John Deere - Case Study",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
          qrCode: "./assets/images/case-studies/case-study-1-qr-code.png",
        },
      ],
      dataInsightsCompleteImage: {
        file: "./assets/images/data-insights/data-insights-top-complete.png",
      },
    },
    {
      name: "Sports",
      description:
        "Today's coaching teams can spend 70% of their time or more collecting and shaping data -- typically from a dozen-plus different unstructured data streams, all housed in different places.",
      image: "./assets/images/sectors/sports.png",
      id: "sports",
      color: "#F29900",
      accentColor: "#FDD663",
      menuIcon: { file: "./assets/images/icons/sports/sports-11.png" },
      spirals: [
        { file: "./assets/images/icons/sports/sports-spiral-1.png" },
        { file: "./assets/images/icons/sports/sports-spiral-2.png" },
      ],
      icons: [
        { file: "./assets/images/icons/sports/sports-1.png" },
        { file: "./assets/images/icons/sports/sports-2.png" },
        { file: "./assets/images/icons/sports/sports-3.png" },
        { file: "./assets/images/icons/sports/sports-4.png" },
        { file: "./assets/images/icons/sports/sports-5.png" },
        { file: "./assets/images/icons/sports/sports-6.png" },
        { file: "./assets/images/icons/sports/sports-7.png" },
        { file: "./assets/images/icons/sports/sports-8.png" },
        { file: "./assets/images/icons/sports/sports-9.png" },
        { file: "./assets/images/icons/sports/sports-10.png" },
        { file: "./assets/images/icons/sports/sports-11.png" },
        { file: "./assets/images/icons/sports/sports-12.png" },
        { file: "./assets/images/icons/sports/sports-13.png" },
        { file: "./assets/images/icons/sports/sports-14.png" },
      ],
      dataTypes: [
        {
          name: "plays",
          icon: "./assets/images/icons/sports/sports-1.png",
          shape: 0,
          type: "image",
        },
        {
          name: "players",
          icon: "./assets/images/icons/sports/sports-1.png",
          shape: 0,
          type: "image",
        },

        {
          name: "temperature",
          icon: "./assets/images/icons/sports/sports-2.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "moisture",
          icon: "./assets/images/icons/sports/sports-1.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "speed",
          icon: "./assets/images/icons/sports/sports-3.png",
          shape: 1,
          type: "sensors",
        },
        {
          name: "pressure",
          icon: "./assets/images/icons/sports/sports-4.png",
          shape: 1,
          type: "sensors",
        },

        {
          name: "player history",
          icon: "./assets/images/icons/sports/sports-6.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "coach assessments",
          icon: "./assets/images/icons/sports/sports-1.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "workouts",
          icon: "./assets/images/icons/sports/sports-12.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "vitals",
          icon: "./assets/images/icons/sports/sports-14.png",
          shape: 2,
          type: "feeds",
        },
        {
          name: "stats",
          icon: "./assets/images/icons/sports/sports-13.png",
          shape: 2,
          type: "feeds",
        },
      ],
      dataInsights: [
        {
          title: "Crop Health",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Optimized Planting Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Crop Rotation Schedule",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Disease Detection",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
        {
          title: "Fertilization Planning",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
        },
      ],
      caseStudies: [
        {
          videoURL: "",
          subTitle: "Crop Health",
          title: "John Deere - Case Study",
          text: "Lorem ipsum dolor sit amet consectetur. Augue mauris quis mi scelerisque ornare sed in feugiat. Ultricies ultrices nibh amet nec proin arcu imperdiet. Dolor non sit ac tristique placerat tellus. Porttitor dolor scelerisque est nibh faucibus est. Augue diam blandit amet cras arcu pellentesque dignissim quis. Urna aliquam amet quam platea magna.",
          qrCode: "./assets/images/case-studies/case-study-1-qr-code.png",
        },
      ],
      dataInsightsCompleteImage: {
        file: "./assets/images/data-insights/data-insights-top-complete.png",
      },
    },
  ],

  pillars: {
    title: "Google’s Five Pillars",
    next: "Next",
    learnMore: "Learn More",
    cards: [
      {
        id: "open",
        title: "Open",
        text: "Our solutions use the best of Google’s open, hybrid and multi-cloud solutions",
        image: "./assets/images/pillars/pillar-1.png",
      },
      {
        id: "intelligent",
        title: "Intelligent",
        text: "Industry leader in AI/ML and data insights",
        image: "./assets/images/pillars/pillar-2.png",
      },
      {
        id: "secure",
        title: "Secure",
        text: "Full control, privacy and protection of your data",
        image: "./assets/images/pillars/pillar-3.png",
      },
      {
        id: "sustainable",
        title: "Sustainable",
        text: "100% renewable energy, zero net carbon emissions",
        image: "./assets/images/pillars/pillar-4.png",
      },
      {
        id: "collaborative",
        title: "Collaborative",
        text: "The best way to create, communicate and collaborate",
        image: "./assets/images/pillars/pillar-5.png",
      },
    ],
  },

  dataProcessing: {
    nodes: [
      {
        title: "Raw data",
        process: "Big Query",
        icon: "./assets/images/processing/process-1.svg",
      },
      {
        title: "Data processing",
        process: "Cloud Data Flow",
        icon: "./assets/images/processing/process-2.svg",
      },
      {
        title: "Transformed data",
        process: "Cloud Storage",
        icon: "./assets/images/processing/process-3.svg",
      },
      {
        title: "Model training",
        process: "Vertex AI Training",
        icon: "./assets/images/processing/process-4.svg",
      },
      {
        title: "Trained model",
        process: "Cloud Storage",
        icon: "./assets/images/processing/process-5.svg",
      },
      {
        title: "Deployed model",
        process: "Vertex AI Prediction",
        icon: "./assets/images/processing/process-6.svg",
      },
    ],
  },

  dataInsights: {
    dataInsightsTitle: "Data Insights",
    addmMore: "Add More Data Types",
    restart: "Restart",
    caseStudies: "View Case Studies",
  },
};

/**
 * Firebase data
 */

const firebaseConfig = {
  apiKey: "AIzaSyCfwitX1ONOcEDqIImx7105vA3HmP_zdEA",
  authDomain: "ebc-2021-prod.firebaseapp.com",
  databaseURL: "https://ebc-2021-prod-default-rtdb.firebaseio.com",
  projectId: "ebc-2021-prod",
  storageBucket: "ebc-2021-prod.appspot.com",
  messagingSenderId: "690480793393",
  appId: "1:690480793393:web:9e51c74af9a5e0cbf95210",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const useData = create((set) => ({
  data,
  fetch: async () => {
    const dataFetch = {};
    // fetch sectors
    const sectorsQuerySnapshot = await getDocs(collection(db, "ecs_primer"));
    dataFetch.sectors = sectorsQuerySnapshot.docs.map((doc) => {
      const d = doc.data();
      const shapes = {
        image: 0,
        sensors: 1,
        feeds: 2,
      };
      // transform data
      return {
        name: d.asset_name,
        description: d.intro_copy,
        dataTypes: d.statistics.map((s) => ({
          ...s,
          shape: shapes[s.type],
        })),
        dataInsights: d.outcomes.map((o) => ({
          title: o.title,
          text: o.description,
        })),
        dataProcessingCopy: {
          "Data processing": d.data_processing_rollover_copy,
          "Deployed model": d.deployed_model_rollover_copy,
          "Model training": d.model_training_rollover_copy,
          "Raw data": d.raw_data_rollover_copy,
          "Trained model": d.trained_model_rollover_copy,
          "Transformed data": d.transformed_data_rollover_copy,
        },
        caseStudies: [
          {
            videoURL: d.industry_video_url,
            title: d.case_study,
            qrCode: `./assets/images/icons/${d.asset_name.toLowerCase().replace(/\s+/g, "-")}/qr.png`,
          },
        ],
      };
    });

    // fetch pillars
    const pillarsQuerySnapshot = await getDocs(collection(db, "ecs_primer_general"));
    const d = pillarsQuerySnapshot.docs[0].data();
    // transform data
    dataFetch.pillars = {
      cards: [...Array(5).keys()].map((n) => ({
        text: d[`why_google_cloud_pillar_${n + 1}_copy`],
        image: d[`file${n + 1}`],
      })),
    };

    // merge fetched data with local data
    const dataMerge = { sectors: [], pillars: {} };
    // merge sectors
    dataFetch.sectors.forEach((sector) => {
      const localDataSector = data.sectors.find((s) => s.name === sector.name);
      dataMerge.sectors.push({ ...localDataSector, ...sector });
    });
    // merge pillars
    const cards = [];
    dataFetch.pillars.cards.forEach((card, i) => {
      cards.push({ ...data.pillars.cards[i], ...card });
    });
    dataMerge.pillars = { ...data.pillars, cards };

    const newData = { ...data, ...dataMerge };
    console.log(newData);
    set({ data: newData });
  },
}));

export { data, useData };

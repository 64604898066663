import create from "zustand";

const useStore = create((set) => ({
  appState: null, // intro, icons,  pillars, sectorsMenu, dataTypes, dataProcessing, dataInsights, dataDetails, dataDetailsDeepLink
  setAppState: (string) => set(() => ({ appState: string })),

  caseStudiesOpen: false,
  setCaseStudiesOpen: (bool) => set(() => ({ caseStudiesOpen: bool })),

  currentDragging: null,
  setCurrentDragging: (string) => set(() => ({ currentDragging: string })),

  currentDataTypeDragging: null,
  setCurrentDataTypeDragging: (string) => set(() => ({ currentDataTypeDragging: string })),

  activeSectors: [],
  setActiveSectors: (array) => set(() => ({ activeSectors: array })),

  selectedSectors: [],
  setSelectedSectors: (array) => set(() => ({ selectedSectors: array })),

  activeIconPosition: {},
  setActiveIconPosition: (pos) => set((state) => ({ activeIconPosition: { ...state.activeIconPosition, ...pos } })),

  selectedSector: null, // null
  setSelectedSector: (number) => set(() => ({ selectedSector: number })),

  selectedDataTypes: [], // []
  setSelectedDataTypes: (array) => set(() => ({ selectedDataTypes: array })),
}));

export default useStore;

import "./DataInsights.scss";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { useData } from "../../data/data";
import modelImage from "../../assets/images/data-insights-model.png";
import CompleteIcon from "../../assets/images/data-insight-complete-icon.svg";
import VideoPlayIcon from "../../assets/images/play-icon.svg";
// import ReactPlayer from "react-player";

const DataInsights = () => {
  const element = useRef();
  const cardElement = useRef();
  const tl = useRef();
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const selectedSector = useStore((state) => state.selectedSector);
  const setSelectedSector = useStore((state) => state.setSelectedSector);
  const setCaseStudiesOpen = useStore((state) => state.setCaseStudiesOpen);
  const data = useData((state) => state.data);
  const progressBarRef = useRef([]);
  const progressBarPathRef = useRef([]);
  const progressStatus = useRef([]);
  const progressBarValue = useRef([]);
  const progressIconRef = useRef([]);
  const menuItemTitle = useRef([]);
  const menuItemText = useRef([]);
  const learnMoreRef = useRef([]);
  const closeMoreRef = useRef([]);

  const dataInsightsCompleteImageRef = useRef();
  const modelImageRef = useRef();

  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoPlayIconRef = useRef();
  const [url, setUrl] = useState(null);
  const [title, setTitle] = useState(null);
  const [subTitle, setSubTitle] = useState(null);
  const [text, setText] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  useEffect(() => {
    if (appState === "dataInsights") {
      tl.current = gsap.timeline({});

      tl.current.set(element.current, { clearProps: "all" }, 0);
      tl.current.set(progressBarValue.current, { clearProps: "all" }, 0);
      tl.current.set(progressBarPathRef.current, { clearProps: "all" }, 0);
      tl.current.set(progressStatus.current, { clearProps: "all" }, 0);
      tl.current.set(menuItemTitle.current, { clearProps: "all" }, 0);
      tl.current.set(learnMoreRef.current, { clearProps: "all" }, 0);
      tl.current.set(progressIconRef.current, { clearProps: "all" }, 0);
      tl.current.set(modelImageRef.current, { clearProps: "all" }, 0);
      tl.current.set(
        dataInsightsCompleteImageRef.current,
        { clearProps: "all" },
        0
      );

      tl.current.set(element.current, { x: "+300%" }, 0);
      tl.current.set(element.current, { autoAlpha: 1 }, 0);
      tl.current.to(
        element.current,
        {
          x: 0,
          duration: 0.7,
          ease: "sine.out",
        },
        0.1
      );

      tl.current.fromTo(
        progressBarPathRef.current,
        {
          "stroke-dasharray": "0, 100",
        },
        {
          "stroke-dasharray": "100, 100",
          duration: 1,
          stagger: 1.5,
        },
        1
      );

      tl.current.fromTo(
        progressBarValue.current,
        { textContent: 0 },
        {
          textContent: 100,
          snap: { textContent: 1 },
          duration: 1,
          stagger: 1.5,
        },
        1
      );

      tl.current.set(
        progressStatus.current,
        {
          textContent: "In Progress...",
          color: "#F9AB00",
          stagger: 1.5,
        },
        1
      );

      tl.current.set(
        menuItemTitle.current,
        {
          color: "#202124",
          stagger: 1.5,
        },
        2
      );

      tl.current.set(
        progressStatus.current,
        {
          textContent: "",
          color: "#FFFFFF",
          stagger: 1.5,
        },
        2
      );

      tl.current.set(
        learnMoreRef.current,
        {
          autoAlpha: 1,
          stagger: 1.5,
        },
        2
      );

      tl.current.to(
        progressIconRef.current,
        {
          autoAlpha: 1,
          stagger: 1.5,
          duration: 0.5,
        },
        2.25
      );

      tl.current.fromTo(
        progressBarValue.current,
        { autoAlpha: 1 },
        {
          autoAlpha: 0,
          stagger: 1.5,
          duration: 0.5,
        },
        2
      );

      tl.current.fromTo(
        progressBarRef.current,
        { autoAlpha: 1 },
        {
          autoAlpha: 0,
          stagger: 1.5,
          duration: 0.5,
        },
        2
      );
      tl.current.to(
        modelImageRef.current,
        { autoAlpha: 0, duration: 0.5 },
        "-=.5"
      );
      tl.current.to(
        dataInsightsCompleteImageRef.current,
        {
          autoAlpha: 1,
          duration: 0.5,
        },
        "-=.5"
      );
      tl.current.call(() => {
        setAppState("dataDetails");
      }, null);
    }

    if (appState === "dataDetails") {
      tl.current = gsap.timeline({});
      tl.current.to(element.current, {
        right: "200px",
        width: "1400px",
        duration: 0.5,
      });
    }

    if (appState === "returnToDataTypes") {
      tl.current = gsap.timeline({});
      tl.current.to(element.current, {
        right: "200px",
        x: "+200%",
        width: "640px",
        ease: "sine.inOut",
        duration: 1,
      });

      tl.current.to(modelImageRef.current, { autoAlpha: 1, duration: 0.5 });
      tl.current.to(dataInsightsCompleteImageRef.current, {
        autoAlpha: 0,
        duration: 0.5,
      });
    }

    if (appState === "dataDetailsDeepLink") {
      tl.current = gsap.timeline({ delay: 4 });
      tl.current.set(element.current, { x: "+300%" }, 0);
      tl.current.set(element.current, { autoAlpha: 1 }, 0);
      tl.current.to(
        element.current,
        {
          x: 0,
          duration: 0.7,
          ease: "sine.out",
        },
        0.1
      );

      tl.current.set(
        menuItemTitle.current,
        {
          color: "#202124",
        },
        0
      );

      tl.current.set(
        progressStatus.current,
        {
          textContent: "",
          color: "#FFFFFF",
        },
        0
      );

      tl.current.set(
        learnMoreRef.current,
        {
          autoAlpha: 1,
        },
        0
      );

      tl.current.to(
        progressIconRef.current,
        {
          autoAlpha: 1,
        },
        0
      );

      tl.current.set(
        progressBarValue.current,
        {
          autoAlpha: 0,
        },
        0
      );

      tl.current.to(
        progressBarRef.current,
        {
          autoAlpha: 0,
        },
        0
      );
      tl.current.to(modelImageRef.current, { autoAlpha: 0, duration: 0.5 });
      tl.current.to(
        dataInsightsCompleteImageRef.current,
        {
          autoAlpha: 1,
          duration: 0.5,
        },
        0
      );

      tl.current.to(
        element.current,
        {
          right: "200px",
          width: "1400px",
          duration: 0.5,
        },
        0
      );
    }
  }, [appState]);

  useEffect(() => {
    if (selectedSector === undefined || selectedSector === null) return;
    //defaults to the first case study for content
    setSubTitle(data.sectors[selectedSector].caseStudies[0].subTitle);
    setTitle(data.sectors[selectedSector].caseStudies[0].title);
    setText(data.sectors[selectedSector].caseStudies[0].text);
    setQrCode(data.sectors[selectedSector].caseStudies[0].qrCode);
    setUrl(data.sectors[selectedSector].caseStudies[0].videoURL);
  }, [selectedSector]);

  useEffect(() => {
    console.log("data update", data);
    if (selectedSector === null) return;
    setSubTitle(data.sectors[selectedSector].caseStudies[0].subTitle);
    setTitle(data.sectors[selectedSector].caseStudies[0].title);
    setText(data.sectors[selectedSector].caseStudies[0].text);
    setQrCode(data.sectors[selectedSector].caseStudies[0].qrCode);
    setUrl(data.sectors[selectedSector].caseStudies[0].videoURL);
  }, [data]);

  const openMenuItem = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    console.log(id);
    tl.current = gsap.timeline({});
    tl.current.to(
      menuItemText.current[id],
      {
        height: "auto",
        "padding-bottom": "12px",
        autoAlpha: 1,
        scaleY: 1,
        transformOrigin: "center top",
        duration: 0.3,
      },
      0
    );
    tl.current.to(closeMoreRef.current[id], { autoAlpha: 1, duration: 0.5 }, 0);
    tl.current.to(learnMoreRef.current[id], { autoAlpha: 0, duration: 0.5 }, 0);
  };

  const closeMenuItem = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    console.log(id);
    tl.current = gsap.timeline({});
    tl.current.to(
      menuItemText.current[id],
      {
        height: "0px",
        "padding-bottom": "0px",
        autoAlpha: 0,
        scaleY: 0,
        transformOrigin: "center top",
        duration: 0.3,
      },
      0
    );
    tl.current.to(closeMoreRef.current[id], { autoAlpha: 0, duration: 0.5 }, 0);
    tl.current.to(learnMoreRef.current[id], { autoAlpha: 1, duration: 0.5 }, 0);
  };

  const restart = (e) => {
    window.location.reload();
  };

  const addMore = (e) => {
    setAppState("returnToDataTypes");
  };

  const caseStudies = (e) => {
    setCaseStudiesOpen(true);
  };

  const playButtonClicked = (e) => {
    e.preventDefault();
    setVideoPlaying(!videoPlaying);
  };

  const videoClicked = (e) => {
    e.preventDefault();
    setVideoPlaying(!videoPlaying);
  };

  useEffect(() => {
    gsap.to(videoPlayIconRef.current, {
      autoAlpha: videoPlaying ? 0 : 1,
      duration: 0.5,
    });
  }, [videoPlaying]);

  return (
    <div ref={element} className="dataInsights">
      <div className="left-content">
        <h1>{data.dataInsights.dataInsightsTitle}</h1>
        <div className="top-image-wrapper">
          <img
            ref={modelImageRef}
            className={"model-image"}
            src={modelImage}
            alt=""
          />
          <img
            ref={dataInsightsCompleteImageRef}
            className={"data-insights-complete-image"}
            src={
              data.sectors[selectedSector || 7].dataInsightsCompleteImage.file
            }
            alt=""
          />
        </div>
        <div className="menu-items-wrapper">
          <div className="menu-items">
            {data.sectors[selectedSector || 7].dataInsights.map((d, i) => {
              return (
                <div
                  className="menu-item"
                  data-index={i}
                  key={"menu-item-" + i}
                >
                  <div className="menu-item-button">
                    <div className="progress">
                      <div className="progress-value">
                        <span
                          ref={(el) => (progressBarValue.current[i] = el)}
                          className="progress-value-number"
                        >
                          0
                        </span>
                        <span className="progress-value-symbol">%</span>
                      </div>
                      <svg
                        viewBox="0 0 36 36"
                        ref={(el) => (progressBarRef.current[i] = el)}
                        className="progress-bar"
                      >
                        <path
                          ref={(el) => (progressBarPathRef.current[i] = el)}
                          d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                          fill="none"
                          stroke="#669DF6"
                          strokeWidth="3"
                          strokeDasharray={`0, 100`}
                        />
                      </svg>
                      <img
                        ref={(el) => (progressIconRef.current[i] = el)}
                        className="progress-icon"
                        src={CompleteIcon}
                        alt=""
                      />
                    </div>
                    <span
                      ref={(el) => (menuItemTitle.current[i] = el)}
                      className="title"
                    >
                      {d.title}
                    </span>
                    <span
                      ref={(el) => (progressStatus.current[i] = el)}
                      className="progressText"
                    >
                      In Progress...
                    </span>
                    <div className="buttons">
                      <button
                        onClick={openMenuItem}
                        data-id={i}
                        ref={(el) => (learnMoreRef.current[i] = el)}
                        className="learnMore"
                      >
                        Learn More
                      </button>
                      <button
                        onClick={closeMenuItem}
                        data-id={i}
                        ref={(el) => (closeMoreRef.current[i] = el)}
                        className="closeMore"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                  <div
                    ref={(el) => (menuItemText.current[i] = el)}
                    className="menu-item-content"
                  >
                    {d.text}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={"content"}>
        <div
          className="content-media"
          // onClick={videoClicked}
        >
          <iframe
            src={url ? url + "/embed?start=true&loop=true&delayms=3000" : ""}
            width={700}
            height={440}
            allowFullScreen={true}
          ></iframe>
          {/* <ReactPlayer
            url={url}
            playing={videoPlaying}
            loop={true}
            config={{
              youtube: {
                playerVars: { showinfo: 1, controls: 0, modestbranding: 1 },
              },
            }}
          />
          <button
            ref={videoPlayIconRef}
            onClick={playButtonClicked}
            className="play-button"
          >
            <img src={VideoPlayIcon} alt="Play" />
          </button> */}
        </div>
        <div className="content-media-info">
          <div className="qr-code">
            <img alt="" src={qrCode} />
          </div>
          <div className="info">
            {/* <h4>{subTitle}</h4> */}
            <h1>{title}</h1>
            {/* <p>{text}</p> */}
          </div>
        </div>
        <div className="content-buttons">
          <button onClick={addMore} className="addMoreButton">
            {data.dataInsights.addmMore}
          </button>
          <button onClick={restart} className="restartButton">
            {data.dataInsights.restart}
          </button>
          <button onClick={caseStudies} className="caseStudiesButton">
            {data.dataInsights.caseStudies}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DataInsights;

import "./IntroGoogleLogo.scss";
import { useData } from "../../data/data";
import React, { Fragment, useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { ReactComponent as Icon } from "../../assets/images/logo.svg";
import BlueRing from "../../assets/images/blue-ring.png";

const sprials = [];

const Spiral = (props) => {
  const ref = useRef();
  const ref2 = useRef();
  const tl = useRef();
  const appState = useStore((state) => state.appState);
  const [visible, setVisible] = useState(false);

  const selectedSectors = useStore((state) => state.selectedSectors);

  useEffect(() => {
    if (appState === "ringsTransition") {
      tl.current = new gsap.timeline({});
      tl.current.to([ref.current, ref2.current], {
        autoAlpha: 0,
        duration: 0.8,
        delay: 0.5,
      });
    }
  }, [appState]);

  useEffect(() => {
    if (selectedSectors.includes(props.sector) && !visible) {
      console.log(props.sector, visible);
      // aniamte this spiral on
      tl.current = new gsap.timeline({});
      tl.current.fromTo(
        [ref.current, ref2.current],
        { autoAlpha: 0, scale: 2 },
        {
          autoAlpha: 1,
          scale: 1 - props.index * 0.01,
          stagger: 0.05,
          ease: "back.inOut",
          duration: 0.7,
          onComplete: () => {
            setVisible(true);
          },
        },
        0
      );
      tl.current.to(
        ref.current,
        {
          rotation: -360,
          repeat: -1,
          ease: "none",
          duration: 50 + props.index * 2.5,
        },
        0
      );
      tl.current.to(
        ref2.current,
        {
          rotation: -360,
          repeat: -1,
          ease: "none",
          duration: 70 + props.index * 2.5,
        },
        0
      );
    }

    // TODO: If needed can reverse the above anaimtion and stop rotation if
    // we need mechanism to return selected sectors back to the world
  }, [selectedSectors]);
  return (
    <>
      <img
        ref={ref}
        src={
          "./assets/images/icons/" + props.id + "/" + props.id + "-spiral-3.png"
        }
        className="spiral"
      />
      <img
        ref={ref2}
        src={
          "./assets/images/icons/" + props.id + "/" + props.id + "-spiral-4.png"
        }
        className="spiral"
      />
    </>
  );
};

const Ring = (props) => {
  const appState = useStore((state) => state.appState);
  const tl = useRef();
  const ref = useRef();

  useEffect(() => {
    if (appState === "ringsTransition") {
      console.log("ringsTransition");
      tl.current = new gsap.timeline({});
      tl.current.set(ref.current, { scale: 0 }, 0);
      tl.current.to(
        ref.current,
        { autoAlpha: 1, duration: 0.2, delay: 0.5 },
        0
      );
      tl.current.to(
        ref.current,
        {
          scale: 1,
          ease: "circ.in",
          duration: 2.5,
          delay: props.index * 0.03,
        },
        0
      );
      tl.current.to(
        ref.current,
        { autoAlpha: 0, duration: 0.5 },
        2.3 + props.index * 0.03
      );
    }
  }, [appState]);

  return (
    <svg
      id={props.sector + "-ring"}
      className="ring"
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4000 4000"
    >
      <circle
        cx="2000"
        cy="2000"
        r="1940"
        style={{
          fill: "none",
          stroke: props.color,
          strokeWidth: "80px",
        }}
      />
    </svg>
  );
};

const IntroGoogleLogo = ({ style }) => {
  const element = useRef();
  const tl = useRef();
  const blueRing = useRef();
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const data = useData((state) => state.data);

  useEffect(() => {
    tl.current = gsap.timeline({});
    if (appState === "intro" || appState === "icons") {
      tl.current.set(element.current, { "pointer-events": "all" });
      tl.current.to(
        element.current,
        {
          autoAlpha: 1,
          duration: 1,
          ease: "power2.out",
        },
        2
      );
    } else {
      tl.current.set(element.current, {
        "pointer-events": "none",
      });
    }

    if (appState === "ringsTransition") {
      tl.current.set(blueRing.current, { scale: 0 }, 0);
      tl.current.to(blueRing.current, {
        autoAlpha: 1,
        duration: 3.5,
        delay: 0.5,
        scale: 1,
        ease: "back.out",
      });
      tl.current.to(blueRing.current, {
        autoAlpha: 1,
        duration: 3,
        scale: 1.2,
        yoyo: true,
        repeat: 2,
        ease: "sine.inOut",
      });

      tl.current.call(
        () => {
          setAppState("pillars");
        },
        null,
        4
      );
    }

    if (appState === "preLaunchLoop") {
      tl.current.to(blueRing.current, {
        overwrite: true,
        scale: 0,
        duration: 2,
        ease: "sine.in",
      });

      tl.current.to(
        element.current,
        {
          autoAlpha: 0,
          duration: 1,
          onComplete: () => {
            window.location.reload();
          },
        },
        1
      );
    }

    if (appState === "pillars") {
      tl.current.to(element.current, {
        autoAlpha: 0,
        duration: 0.5,
        ease: "power2.in",
      });
    }

    if (
      appState === "dataTypes" ||
      appState === "dataProcessing" ||
      appState === "dataInsights" ||
      appState === "dataDetails"
    ) {
      tl.current.set(element.current, {
        display: "none",
      });
    }
  }, [appState]);

  const start = (e) => {
    e.stopPropagation();
    console.log("here");
    setAppState("ringsTransition");
  };

  return (
    <div ref={element} className="introGoogleIcon" onPointerDown={start}>
      <Icon />
      <img src={BlueRing} className="blueRing" ref={blueRing} />
      {data.sectors.map((s, i) => {
        return (
          <Fragment key={"ring_" + i}>
            <Spiral key={"spiral_" + i} id={s.id} index={i} sector={s.id} />
            <Ring
              key={"ring_" + i}
              id={s.id}
              index={i}
              sector={s.id}
              color={s.color}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

export default IntroGoogleLogo;

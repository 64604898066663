import "./Pillars.scss";
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { useData } from "../../data/data";
import { ReactComponent as Arrow } from "../../assets/images/next-arrow.svg";

const Pillars = (props) => {
  const element = useRef();
  const title = useRef();
  const tl = useRef();
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const data = useData((state) => state.data);
  const cardsRef = useRef([]);
  const nextButtonRef = useRef();

  useEffect(() => {
    if (appState === "pillars") {
      tl.current = gsap.timeline({ delay: 0.5 });
      tl.current.set(cardsRef.current, { autoAlpha: 1 });
      tl.current.set(element.current, { autoAlpha: 1 });
      tl.current.to(title.current, { autoAlpha: 1, duration: 1 }, 2);
      tl.current.from(
        cardsRef.current,
        {
          x: "-=4000",
          duration: 1.8,
          ease: "back.out",
          stagger: 0.1,
        },
        0
      );
      tl.current.set(nextButtonRef.current, { autoAlpha: 1, x: 200 }, 2.0);
      tl.current.to(
        nextButtonRef.current,
        {
          x: 0,
          duration: 1,
          ease: "back.out",
        },
        2.0
      );
    }

    if (appState === "hidePillars") {
      tl.current = gsap.timeline({});
      tl.current.to(title.current, { autoAlpha: 0, duration: 0.5 }, 0);
      tl.current.to(
        cardsRef.current,
        {
          x: "+=4000",
          duration: 1.0,
          ease: "sine.in",
          stagger: -0.1,
        },
        0
      );
      tl.current.to(
        nextButtonRef.current,
        {
          x: 200,
          duration: 0.5,
          ease: "sine.in",
        },
        0
      );
      tl.current.set(cardsRef.current, { autoAlpha: 0 });
      tl.current.set(element.current, { autoAlpha: 0 });
      tl.current.set(nextButtonRef.current, { autoAlpha: 0 });
      tl.current.call(
        () => {
          setAppState("sectorsMenu");
        },
        null,
        0.5
      );
    }
  }, [appState]);

  const nextClicked = (e) => {
    setAppState("hidePillars");
  };

  return (
    <div ref={element} className="pillars">
      <h1 ref={title} className="title">
        {data.pillars.title}
      </h1>
      <div className="cards">
        {data.pillars.cards.map((c, i) => {
          return (
            <div
              className="card"
              ref={(el) => (cardsRef.current[i] = el)}
              key={"pillar-card-" + i}
            >
              <img className="card-image" src={c.image} />
              <h2 className="card-title">{c.title}</h2>
              <p className="card-text">{c.text}</p>
              {/* <button className="card-button">{data.pillars.learnMore}</button> */}
            </div>
          );
        })}
      </div>
      <button ref={nextButtonRef} className="nextButton" onClick={nextClicked}>
        {data.pillars.next} <Arrow />
      </button>
    </div>
  );
};

export default Pillars;

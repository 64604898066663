import "./DataTypesDropBox.scss";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { useData } from "../../data/data";
import { ReactComponent as InstructionImage } from "../../assets/images/data-types-drop-zone-instruction.svg";
import { ReactComponent as ButtonImage } from "../../assets/images/generate-insights-button.svg";

const DataTypesDropBox = ({ style }) => {
  const element = useRef();
  const tl = useRef();
  const instructionRef = useRef();
  const buttonRef = useRef();
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const selectedDataTypes = useStore((state) => state.selectedDataTypes);
  const data = useData((state) => state.data);

  useEffect(() => {
    if (appState === "dataTypes") {
      tl.current = gsap.timeline({ delay: 0.6 });
      tl.current.set(element.current, { y: 100, autoAlpha: 0 }, 0);
      tl.current.to(
        element.current,
        {
          y: 0,
          autoAlpha: 1,
          duration: 1.0,
          ease: "sine.inOut",
        },
        0.0
      );
    }

    if (appState === "returnToDataTypes") {
      tl.current = gsap.timeline({ delay: 0.6 });
      tl.current.set(element.current, { clearProps: "all" });
    }

    if (appState === "dataProcessing") {
      tl.current = gsap.timeline({});

      tl.current.set(element.current, { "z-index": 0 });

      tl.current.to(
        element.current,
        {
          x: "-100%",
          autoAlpha: 0,
          duration: 1,
          ease: "sine.inOut",
        },
        0.0
      );
      tl.current.set(element.current, { display: "none" });
    }

    if (appState === "dataDetailsDeepLink") {
      tl.current = gsap.timeline({});
      tl.current.set(element.current, { "z-index": 0 });

      tl.current.set(
        element.current,
        {
          x: "-100%",
          autoAlpha: 0,
        },
        0.0
      );
      tl.current.set(element.current, { display: "none" });
    }
  }, [appState]);

  useEffect(() => {
    if (selectedDataTypes.length) {
      tl.current = gsap.timeline({});
      tl.current.to(
        instructionRef.current,
        {
          autoAlpha: 0,
          duration: 1,
        },
        0
      );
      tl.current.to(
        buttonRef.current,
        {
          autoAlpha: 1,
          duration: 0.5,
        },
        1
      );
    } else {
      tl.current = gsap.timeline({});
      tl.current.to(
        instructionRef.current,
        {
          autoAlpha: 1,
          duration: 1,
        },
        0
      );
      tl.current.to(
        buttonRef.current,
        {
          autoAlpha: 0,
          duration: 0.5,
        },
        0
      );
    }
  }, [selectedDataTypes]);

  const buttonClicked = (e) => {
    setAppState("dataProcessing");
  };

  return (
    <div ref={element} className="dataTypesDropBox">
      <h1>{data.dataTypes.rawData}</h1>
      <InstructionImage ref={instructionRef} />
      <button className={"button"} ref={buttonRef} onClick={buttonClicked}>
        <ButtonImage />
      </button>
    </div>
  );
};

export default DataTypesDropBox;

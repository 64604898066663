import { useData } from "../../data/data";
import "./Scene3D.scss";
import React, { Fragment, Suspense, useEffect, useRef, useState, useMemo } from "react";
import gsap from "gsap";
import useStore from "../../store";
import * as THREE from "three";
import { Canvas, useFrame } from "@react-three/fiber";
import { Text, useTexture } from "@react-three/drei";
import { useGesture } from "@use-gesture/react";

const getPoint = (radius, setIndex, sectorIndex, i) => {
  var u = randomValues[1000 * setIndex + 100 * sectorIndex + 5 * i + 2];
  var v = randomValues[1000 * setIndex + 100 * sectorIndex + 5 * i + 3];
  var theta = u * 2.0 * Math.PI;
  var phi = Math.acos(2.0 * v - 1.0);
  var r = Math.cbrt(0.2 + randomValues[1000 * setIndex + 100 * sectorIndex + 5 * i + 4]);
  var sinTheta = Math.sin(theta);
  var cosTheta = Math.cos(theta);
  var sinPhi = Math.sin(phi);
  var cosPhi = Math.cos(phi);
  var x = r * sinPhi * cosTheta;
  var y = r * sinPhi * sinTheta * 0.5;
  var z = r * cosPhi + (r * cosPhi) / (x * y * 20);
  return [x * radius, y * radius, z * radius];
};

// dimensioned for 5 sets * 10 sectors * 20 icons * 5 random props
const randomValues = Array.from({ length: 5000 }, () => Math.random());

const Icon = (props) => {
  const spriteMatRef = useRef();
  const ref = useRef();
  const spiral1 = useRef();
  const spiral2 = useRef();
  const textRef = useRef();
  const pillRef = useRef();
  const pillMatRef = useRef();
  const iconRef = useRef();
  const spiralMat1 = useRef();
  const spiralMat2 = useRef();
  const tl = useRef();
  const texture = useTexture(props.texture);
  const [active, setActive] = useState(false);
  const dragging = useRef(false);
  const activeSectors = useStore((state) => state.activeSectors);
  const setActiveSectors = useStore((state) => state.setActiveSectors);
  const selectedSectors = useStore((state) => state.selectedSectors);
  const setSelectedSectors = useStore((state) => state.setSelectedSectors);
  const activeIconPosition = useStore((state) => state.activeIconPosition);
  const setActiveIconPosition = useStore((state) => state.setActiveIconPosition);
  const pointerDown = useRef(false);
  const data = useData((state) => state.data);

  const drag = useGesture({
    onDragStart: ({ event, xy: [x, y] }) => {
      event.stopPropagation();
      dragging.current = false;
      pointerDown.current = true;

      // if inactive, set this icon to active
      if (!active) {
        setActive(true);
        setActiveIconPosition({ [props.sector]: ref.current.position });
        if (!activeSectors.includes(props.sector)) {
          const a = [...activeSectors];
          a.push(props.sector);
          setActiveSectors(a);
        }
        
        // move icon to touch point
        const mouse = new THREE.Vector2();
        mouse.x = (x / window.innerWidth) * 2 - 1;
        mouse.y = -(y / window.innerHeight) * 2 + 1;
        var vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
        vector.unproject(event.camera);
        var dir = vector.sub(event.camera.position).normalize();
        var distance = (-event.camera.position.z + 6.5) / dir.z;
        var pos = event.camera.position.clone().add(dir.multiplyScalar(distance));

        gsap.to(ref.current.position, {
          x: pos.x,
          y: pos.y,
          z: pos.z,
          duration: 0.3,
          ease: "back.out",
          onComplete: () => {
            // if the touch point is still active once this icon has reached the touch point, enable dragging
            if (pointerDown.current) {
              dragging.current = true;
            } else {
              dragging.current = false;
            }
          },
        });
      } else {
        // if already active, enable dragging
        dragging.current = true;
      }
    },
    onDrag: ({ event, xy: [x, y] }) => {
      event.stopPropagation();

      if (dragging.current) {
        const mouse = new THREE.Vector2();
        mouse.x = (x / window.innerWidth) * 2 - 1;
        mouse.y = -(y / window.innerHeight) * 2 + 1;

        // drag icon
        var vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
        vector.unproject(event.camera);
        var dir = vector.sub(event.camera.position).normalize();
        var distance = (-event.camera.position.z + 6.5) / dir.z;
        var pos = event.camera.position.clone().add(dir.multiplyScalar(distance));
        ref.current.position.copy(pos);

        // move to center if close enough
        const d = Math.sqrt(Math.pow(mouse.x, 2) + Math.pow(mouse.y, 2));
        if (d < 0.5) {
          console.log("center", props.sectorIndex, props.sector);
          dragging.current = false;
          pointerDown.current = false;
          if (!selectedSectors.includes(props.sector)) {
            const a = [...selectedSectors];
            a.push(props.sector);
            setSelectedSectors(a);
            // animate this icon away
            tl.current = new gsap.timeline({});
            tl.current.to(ref.current.position, { x: 0, y: 0, z: 0, duration: 0.5 }, 0);
            tl.current.to(ref.current.scale, { x: 3.0, y: 3.0, z: 3.0, duration: 0.55 }, 0);
            tl.current.to(
              [spriteMatRef.current, spiralMat1.current, spiralMat2.current, pillMatRef.current],
              { opacity: 0, duration: 0.6, stagger: 0.1 },
              0
            );

            tl.current.to([textRef.current], { fillOpacity: 0, strokeOpacity: 0, duration: 0.6 }, 0);
          }
        }
      }
    },
    onDragEnd: ({ event }) => {
      event.stopPropagation();
      dragging.current = false;
      pointerDown.current = false;
    },
  });

  const activeRef = useRef();
  useEffect(() => {
    activeRef.current = active;
  }, [active]);
  const selectedSectorsRef = useRef();
  useEffect(() => {
    selectedSectorsRef.current = selectedSectors;
  }, [selectedSectors]);

  useEffect(() => {
    // if not active, but this sector just added, animate to transparent and scale to 0
    if (!activeRef.current && activeSectors.includes(props.sector)) {
      tl.current = gsap.timeline({});
      tl.current.to(spriteMatRef.current, { opacity: 0, duration: 0.4 }, 0.6);
      tl.current.set(
        ref.current.scale,
        {
          x: 0,
          y: 0,
          z: 0,
        },
        1.0
      );
    }

    // if active and spirals not showing, show spirals
    if (activeRef.current && spiralMat1.current.opacity !== 1 && !selectedSectorsRef.current.includes(props.sector)) {
      tl.current = gsap.timeline({ delay: 0.0 });
      tl.current.to(spiralMat1.current, { opacity: 1, duration: 0.5 }, 0.8);
      tl.current.to(spiralMat2.current, { opacity: 1, duration: 0.5 }, 0.8);
      tl.current.fromTo(
        spiral1.current.scale,
        { x: 3.0, y: 3.0, z: 3.0 },
        { x: 0.325, y: 0.325, z: 0.325, duration: 1.0, ease: "back.out" },
        0
      );
      tl.current.fromTo(
        spiral2.current.scale,
        { x: 4.1, y: 4.1, z: 4.1 },
        { x: 0.36, y: 0.36, z: 0.36, duration: 1.0, ease: "back.out" },
        0
      );
      tl.current.to(spiralMat1.current, { rotation: "+=.5", duration: 2.5, ease: "sin.out" }, 0);
      tl.current.to(spiralMat2.current, { rotation: "-=1", duration: 3, ease: "sin.out" }, 0);
    }
  }, [activeSectors, props.sector]);

  useFrame((state, delta) => {
    // rotate spirals
    if (active) {
      spiralMat1.current.rotation += delta * 0.01 + Math.abs(props.rotationSpeed) * 0.001;
      spiralMat2.current.rotation += delta * 0.08 + Math.abs(props.rotationSpeed) * 0.001;
    }

    if (!dragging.current) {
      // slightly move and rotate icon
      if (!active) {
        ref.current.position.x += Math.sin(state.clock.elapsedTime * props.rotationSpeed) * 0.0004;
        ref.current.position.y += Math.cos(state.clock.elapsedTime * props.rotationSpeed) * 0.0003;
        ref.current.position.z += Math.cos(state.clock.elapsedTime * props.rotationSpeed) * 0.0005;
        spriteMatRef.current.rotation += delta * props.rotationSpeed;
      } else {
        ref.current.position.x += Math.sin(state.clock.elapsedTime * props.rotationSpeed) * 0.00004;
        ref.current.position.y += Math.cos(state.clock.elapsedTime * props.rotationSpeed) * 0.00003;
        ref.current.position.z += Math.cos(state.clock.elapsedTime * props.rotationSpeed) * 0.00005;
        spriteMatRef.current.rotation += delta * props.rotationSpeed;
      }
    }

    // move inactive icons towards active icon
    if (!active && activeSectors.includes(props.sector) && activeIconPosition[props.sector]) {
      ref.current.position.x += (activeIconPosition[props.sector].x - ref.current.position.x) * 0.05;
      ref.current.position.y += (activeIconPosition[props.sector].y - ref.current.position.y) * 0.05;
      ref.current.position.z += (activeIconPosition[props.sector].z - ref.current.position.z) * 0.05;
    }
  });

  return (
    <group ref={ref} position={props.position} {...drag()}>
      <sprite ref={iconRef} scale={[0.12, 0.12, 0.12]}>
        <spriteMaterial
          ref={spriteMatRef}
          map={texture}
          //color={props.color}
          rotation={props.rotation}
          useScreenCoordinates={true}
        />
      </sprite>

      <sprite ref={spiral1} scale={[0.3, 0.3, 0.3]}>
        <spriteMaterial
          ref={spiralMat1}
          rotation={0}
          opacity={0}
          visible={active}
          map={useTexture(data.sectors[props.sectorIndex].spirals[0].file)}
          useScreenCoordinates={true}
        />
      </sprite>
      <sprite ref={spiral2} scale={[0.35, 0.35, 0.35]}>
        <spriteMaterial
          ref={spiralMat2}
          rotation={0}
          opacity={0}
          visible={active}
          map={useTexture(data.sectors[props.sectorIndex].spirals[1].file)}
          useScreenCoordinates={true}
        />
      </sprite>

      <sprite ref={pillRef} scale={[0.27, 0.27, 0.0]} position={[0, -0.25, 0]}>
        <spriteMaterial map={props.pillTexture} ref={pillMatRef} color={props.accentColor} visible={active} />
      </sprite>
      <Text
        ref={textRef}
        color="#202124"
        anchorX="center"
        anchorY="center"
        fontSize={0.039}
        position={[0, -0.225, 0.0]}
        visible={active}>
        {props.sectorName}
      </Text>
    </group>
  );
};

const IconSet = (props) => {
  const iconsRef = useRef();
  const pillTexture = useTexture("./assets/images/pill.png");
  const data = useData((state) => state.data);

  const icons = useMemo(
    () =>
      data.sectors[props.index].icons.map((e, i) => {
        return (
          <Icon
            key={props.setIndex + "_" + props.sectorIndex + "_" + i}
            position={getPoint(6, props.setIndex, props.sectorIndex, i)}
            rotation={randomValues[1000 * props.setIndex + 100 * props.sectorIndex + 5 * i + 0] * Math.PI * 4}
            rotationSpeed={randomValues[1000 * props.setIndex + 100 * props.sectorIndex + 5 * i + 1] * 0.5 - 0.25}
            texture={e.file}
            sector={data.sectors[props.index].id}
            sectorIndex={props.index}
            sectorName={data.sectors[props.index].name}
            id={i}
            pillTexture={pillTexture}
            color={data.sectors[props.index].color}
            accentColor={data.sectors[props.index].accentColor}
          />
        );
      }),
    [data.sectors, pillTexture, props.index, props.sectorIndex, props.setIndex]
  );

  return <group ref={iconsRef}>{icons}</group>;
};

const Icons = () => {
  const data = useData((state) => state.data);

  const sets = useMemo(
    () =>
      [0, 1, 2, 3, 4].map((_set, j) => {
        return (
          <Fragment key={"set_" + j}>
            {data.sectors.map((s, i) => {
              return <IconSet index={i} sectorIndex={i} setIndex={j} key={"set_" + j + "_sector_" + i} />;
            })}
          </Fragment>
        );
      }),
    [data.sectors]
  );

  return sets;
};

const Scene3D = () => {
  const element = useRef();
  const tl = useRef();
  const coverRef = useRef();
  const appState = useStore((state) => state.appState);
  const [blur, setBlur] = useState(true);

  useEffect(() => {
    if (blur) {
      gsap.to(element.current, {
        filter: "blur(10px) brightness(.5)",
        duration: 2,
      });
      gsap.set(coverRef.current, { display: "block" });
    } else {
      gsap.to(element.current, {
        filter: "blur(0px) brightness(1)",
        duration: 2,
      });
      gsap.set(coverRef.current, { display: "none" });
    }
  }, [blur]);

  useEffect(() => {
    setBlur(appState !== "icons" && appState !== "ringsTransition");

    if (appState === "intro") {
      tl.current = gsap.timeline({ delay: 0 });
      tl.current.fromTo(
        element.current,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          duration: 3.0,
          ease: "power2.inOut",
        }
      );
    }

    if (appState === "ringsTransition") {
      tl.current = gsap.timeline({ delay: 1.6 });
      tl.current.to(element.current, { opacity: 0.0, duration: 2.5 });
    }

    if (appState === "pillars") {
      tl.current = gsap.timeline({});
      tl.current.to(element.current, { opacity: 1.0, duration: 2.5 });
    }
  }, [appState]);

  return (
    <div ref={element} className="scene3D">
      <Suspense fallback={null}>
        <Canvas
          gl={{
            antialias: false,
            transparent: false,
            alpha: false,
          }}
          flat
          linear
          camera={{ fov: 15, near: 0.1, far: 100, position: [0, 0, 12] }}>
          <fog attach="fog" color="black" near={5} far={40} />
          <Icons />
        </Canvas>
        <div ref={coverRef} className="cover"></div>
      </Suspense>
    </div>
  );
};

export default Scene3D;

import "./DataTypesCanvas.scss";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { useData } from "../../data/data";
import { Canvas, useFrame } from "@react-three/fiber";
import { Physics, useBox, useCapsule } from "@react-three/p2";
import { Plane, Text, useTexture } from "@react-three/drei";

const Box = () => {
  //console.log("boxes added");

  const [ref, api] = useBox(() => ({
    args: [14, 0.3],
    mass: 0.0,
    position: [0, -2.2],
  }));

  const [ref1, api1] = useBox(() => ({
    args: [6, 1],
    mass: 0.0,
    position: [3.25, -1.9],
  }));

  const [ref2, api2] = useBox(() => ({
    args: [18, 0.5],
    mass: 0.0,
    position: [-6.8, 0],
    angle: (Math.PI / 180) * -90,
  }));

  const [ref3, api3] = useBox(() => ({
    args: [18, 0.5],
    mass: 0.0,
    position: [6.7, 0],
    angle: (Math.PI / 180) * -90,
  }));

  const [ref4, api4] = useBox(() => ({
    args: [6.5, 2.0],
    mass: 0.0,
    position: [-0.2, -1.3],
    angle: (Math.PI / 180) * -90,
  }));
  return null;
};

const DataType = (props) => {
  const selectedDataTypes = useStore((state) => state.selectedDataTypes);
  const setSelectedDataTypes = useStore((state) => state.setSelectedDataTypes);
  const currentDataTypeDragging = useStore((state) => state.currentDataTypeDragging);
  const setCurrentDataTypeDragging = useStore((state) => state.setCurrentDataTypeDragging);
  const tl = useRef();

  const [ref, api] = useCapsule(() => ({
    mass: 1,
    args: [(1.8 + props.name.length * 0.07) * 0.5, 0.66 * 0.5],
    position: [Math.sin(props.index) * 1.0 - 4.0, 4.25 + props.index * 0.7],
  }));

  const pos = useRef([0, 0]);
  const vel = useRef([0, 0]);

  useEffect(() => {
    setSelectedDataTypes([]);
    const unsubscribe = api.position.subscribe((p) => (pos.current = p));
    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = api.velocity.subscribe((v) => (vel.current = v));
    return unsubscribe;
  }, []);

  useFrame((state) => {
    if (currentDataTypeDragging === props.index) {
      api.velocity.set((state.mouse.x * 10 - pos.current[0]) * 4, (state.mouse.y * 5 - pos.current[1]) * 8);

      //console.log(pos.current[0]);
      if (vel.current[1] > 0.1) {
        //trigger fling
        setCurrentDataTypeDragging(null);
        // console.log(api);
        api.velocity.set(0, 0);
        tl.current = gsap.timeline({
          onComplete: () => {},
        });
        let pos1 = { x: pos.current[0], y: pos.current[1] };
        const a = [...selectedDataTypes];
        a.push(props.index);
        setSelectedDataTypes(a);
        tl.current.to(pos1, {
          x: 3,
          y: 5,
          duration: 1,
          ease: "circ.out",
          onUpdate: () => {
            api.velocity.set(0, 0);
            api.position.set(pos1.x, pos1.y);
          },
        });
        tl.current.to(pos1, {
          x: 3,
          y: 1,
          duration: 0.7,
          ease: "sine.in",
          onUpdate: () => {
            api.velocity.set(0, 0);
            api.position.set(pos1.x, pos1.y);
          },
        });
      }
    }
  });

  const texture = useTexture(props.icon);

  return (
    <Plane
      ref={ref}
      args={[1.1 + props.name.length * 0.08, 0.58]}
      onPointerDown={(e) => {
        e.stopPropagation();
        setCurrentDataTypeDragging(props.index);
      }}
      onPointerUp={(e) => {
        e.stopPropagation();
        setCurrentDataTypeDragging(null);
      }}
      onPointerMissed={(e) => {
        e.stopPropagation();
        setCurrentDataTypeDragging(null);
      }}>
      <meshBasicMaterial color={"white"} transparent={true} map={props.shape} />
      <Text
        color={props.color}
        anchorX="left"
        anchorY="middle"
        fontSize={0.2}
        position={[(1.1 + props.name.length * 0.08) * -0.5 + 0.52, 0.0, 0.01]}>
        {props.name}
      </Text>
      <Plane args={[0.3, 0.3]} position={[(1.1 + props.name.length * 0.08) * -0.5 + 0.3, 0.0, 0.02]}>
        <meshBasicMaterial transparent={true} map={texture} />
      </Plane>
    </Plane>
  );
};

const DataTypesIcons = (props) => {
  const selectedSector = useStore((state) => state.selectedSector);
  const data = useData((state) => state.data);
  const tl = useRef();
  const [localIndex, setLocalIndex] = useState(0);

  const textures = useTexture([
    "./assets/images/data-type-shapes/images-shape.png",
    "./assets/images/data-type-shapes/sensors-shape.png",
    "./assets/images/data-type-shapes/feeds-shape.png",
    "./assets/images/data-type-shapes/multi-cloud-shape.png",
  ]);

  useEffect(() => {
    tl.current = gsap.timeline({});
    tl.current.to(props.parent.current, { autoAlpha: 0, duration: 0.3 });
    tl.current.call(
      () => {
        setLocalIndex(selectedSector || 0);
      },
      null,
      0.5
    );
    tl.current.to(props.parent.current, { autoAlpha: 1, duration: 0.3 }, 0.6);
    return () => {
      tl.current = null;
    };
  }, [selectedSector]);

  return data.sectors[localIndex].dataTypes.map((d, i) => {
    return (
      <DataType
        key={"datatype_" + localIndex + "_" + i}
        index={i}
        name={d.name}
        icon={d.icon}
        type={d.type}
        shape={textures[d.shape]}
        color={data.sectors[localIndex].color}
      />
    );
  });
};

const DataTypesCanvas = () => {
  const element = useRef();
  const canvasRef = useRef();
  const appState = useStore((state) => state.appState);
  const [paused, setPaused] = useState(true);

  useEffect(() => {
    setPaused(appState !== "dataTypes");
  }, [appState]);

  return (
    <div ref={element} className="dataTypesCanvas">
      <Canvas flat={true} ref={canvasRef}>
        <Physics normalIndex={2} allowSleep={true} isPaused={paused}>
          <Box />
          <DataTypesIcons parent={element} />
        </Physics>
      </Canvas>
    </div>
  );
};

export default DataTypesCanvas;

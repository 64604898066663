import "./SectorsMenu.scss";
import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import useStore from "../../store";
import { useData } from "../../data/data";

const SectorsMenu = (props) => {
  const element = useRef();
  const tl = useRef();
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const data = useData((state) => state.data);
  const itemsRef = useRef([]);
  const itemsInnerRef = useRef([]);
  const itemsInnerIconRef = useRef([]);
  const itemsInnerTitleRef = useRef([]);
  const menuItems = useRef();
  const theta = 360 / data.sectors.length;
  const radius = 700;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [menuState, setMenuState] = useState("large");
  const selectedSector = useStore((state) => state.selectedSector);
  const setSelectedSector = useStore((state) => state.setSelectedSector);

  const rotateMenu = () => {
    const angle = theta * selectedIndex * -1;
    menuItems.current.style.transform =
      "translateZ(" + -radius + "px) rotateY(" + angle + "deg)";
    for (var i = 0; i < itemsRef.current.length; i++) {
      const cellAngle = -theta * i - angle;
      itemsInnerRef.current[i].style.transform =
        "rotateY(" + cellAngle + "deg)";
    }
  };

  useEffect(() => {
    for (var i = 0; i < itemsRef.current.length; i++) {
      const cell = itemsRef.current[i];
      const cellAngle = theta * i;
      cell.style.transform =
        "rotateY(" + cellAngle + "deg) translateZ(" + radius + "px)";
      itemsInnerRef.current[i].style.transform =
        "rotateY(-" + cellAngle + "deg)";
    }
  }, []);

  useEffect(() => {
    rotateMenu();
  }, [selectedIndex]);

  useEffect(() => {
    if (selectedSector === null) return;
    const tl2 = gsap.timeline({});

    // deselected
    tl2.to(
      itemsInnerRef.current,
      {
        background: "#ffffff",
        duration: 0.4,
      },
      0
    );
    tl2.to(
      itemsInnerIconRef.current,
      {
        filter: "none",
        duration: 0.4,
      },
      0
    );
    tl2.to(
      itemsInnerTitleRef.current,
      {
        color: "#202124",
        duration: 0.4,
      },
      0
    );

    // selected
    tl2.to(
      itemsInnerRef.current[selectedSector],
      {
        background: data.sectors[selectedSector].color,
        duration: 0.4,
      },
      0
    );
    tl2.to(
      itemsInnerIconRef.current[selectedSector],
      {
        filter: "brightness(0) invert(1)",
        duration: 0.4,
      },
      0
    );
    tl2.to(
      itemsInnerTitleRef.current[selectedSector],
      {
        color: "#ffffff",
        duration: 0.4,
      },
      0
    );

    tl2.call(
      () => {
        setMenuState("small");
      },
      null,
      0.5
    );
  }, [selectedSector]);

  useEffect(() => {
    // Intro Anaimtion for the menu
    if (appState === "sectorsMenu") {
      tl.current = gsap.timeline({ delay: 0.0 });
      tl.current.set(itemsRef.current, { autoAlpha: 1 });
      tl.current.set(element.current, { autoAlpha: 1 });
      tl.current.from(
        itemsRef.current,
        {
          left: "-=7000",
          duration: 1.2,
          ease: "sine.out",
          stagger: 0.1,
        },
        0
      );
      tl.current.call(
        () => {
          setSelectedIndex(7);
          rotateMenu();
        },
        null,
        0.5
      );
    }

    if (appState === "dataInsights") {
      tl.current = gsap.timeline({});
      tl.current.to(element.current, {
        scale: 0.5,
        width: "1460px",
        left: "-2vw", // TODO: May need some additional math for responsive
        top: "25vh", // TODO: May need some additional math for responsive
        position: "absolute",
        duration: 1,
        ease: "sine.inOut",
      });
    }

    if (appState === "returnToDataTypes") {
      tl.current = gsap.timeline({});
      tl.current.set(itemsRef.current, { autoAlpha: 1 });
      tl.current.set(element.current, { autoAlpha: 1 });
      tl.current.to(element.current, {
        scale: 0.5,
        width: "1460px",
        left: "12.5vw", // TODO: May need some additional math for responsive
        top: "25vh", // TODO: May need some additional math for responsive
        position: "absolute",
        duration: 1,
        ease: "sine.inOut",
      });
      tl.current.call(
        () => {
          setAppState("dataTypes");
        },
        null,
        1.5
      );
    }

    if (appState === "dataDetailsDeepLink") {
      tl.current = gsap.timeline({ delay: 0.0 });
      tl.current.set(itemsRef.current, { autoAlpha: 1 });
      tl.current.set(element.current, { autoAlpha: 1 });
      tl.current.from(
        itemsRef.current,
        {
          left: "-=7000",
          duration: 2,
          ease: "sine.out",
          stagger: 0.1,
        },
        0
      );
      tl.current.call(
        () => {
          setSelectedIndex(selectedSector);
          //rotateMenu();
        },
        null,
        1.5
      );
      tl.current.to(
        element.current,
        {
          scale: 0.5,
          width: "1460px",
          left: "-2vw", // TODO: May need some additional math for responsive
          top: "25vh", // TODO: May need some additional math for responsive
          position: "absolute",
          duration: 1,
          ease: "sine.inOut",
        },
        2
      );
    }
  }, [appState]);

  useEffect(() => {
    if (menuState === "small") {
      // console.log("make menu smaller");
      tl.current = gsap.timeline({});
      tl.current.to(element.current, {
        scale: 0.5,
        width: "1460px",
        left: "12.5vw", // TODO: May need some additional math for responsive
        top: "25vh", // TODO: May need some additional math for responsive
        position: "absolute",
        duration: 1.5,
        ease: "sine.inOut",
      });
      // advance to dataTypes once this anaimtion is nearly done
      tl.current.call(
        () => {
          setAppState("dataTypes");
        },
        null,
        1.5
      );
    }

    if (menuState === "smallLeft") {
      // console.log("make menu smaller and slide to left");
      tl.current = gsap.timeline({});
      tl.current.to(element.current, {
        scale: 0.5,
        width: "1460px",
        left: "0%", // TODO: May need some additional math for responsive
        bottom: "-25%", // TODO: May need some additional math for responsive
        position: "absolute",
        duration: 1,
        ease: "sine.inOut",
      });
    }

    //TODO: If required, add aanimation to make menu go back to center and larger again
  }, [menuState]);

  return (
    <div ref={element} className="sectorsMenu">
      <div className="menu-items" ref={menuItems}>
        {data.sectors.map((s, i) => {
          const menuItemClicked = (e) => {
            e.preventDefault();
            if (appState !== "sectorsMenu" && appState !== "dataTypes") return;
            // console.log(itemsRef.current[i]);
            setSelectedIndex(i); // locally rotate the menu to this one
            setSelectedSector(i); // update the store so other components can react
          };

          return (
            <div
              onPointerDown={menuItemClicked}
              className="menu-item"
              key={"sector-menu-" + i}
              ref={(el) => (itemsRef.current[i] = el)}
            >
              <div
                className="menu-item-inner"
                ref={(el) => (itemsInnerRef.current[i] = el)}
                key={"sector-menu-inner-" + i}
              >
                <img
                  className="image"
                  src={s.menuIcon.file}
                  ref={(el) => (itemsInnerIconRef.current[i] = el)}
                />
                <h1
                  className="title"
                  ref={(el) => (itemsInnerTitleRef.current[i] = el)}
                >
                  {s.name}
                </h1>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectorsMenu;
